import { useEffect, useState } from "react";
import axios from "axios";
import { usePrestigiousContext } from "../../../../Context/appContext";
import { BASE_URL } from "../../../../Constants/Constants";

function PaymentLinks({ match, history, paymentLink, allPaymentLinks, setPaymentLinks, close }) {
  const [contact, setContact] = useState(paymentLink.contact);
  const [type, setType] = useState(paymentLink.type);

  const { currentUser } = usePrestigiousContext();

  useEffect(() => {}, []);

  const update = async () => {
    if (contact && type) {
      let paymentLinks = allPaymentLinks;
      if (paymentLink.isEdit) {
        const index = allPaymentLinks.findIndex(
          (o) => o._id === paymentLink._id
        );
        paymentLinks[index].contact = contact;
        paymentLinks[index].type = type;
      } else {
        paymentLinks = [
          ...allPaymentLinks,
          {
            contact,
            type,
          },
        ];
      }
      try {
        const config = {
          headers: {
            Authorization: `Bearer ${currentUser.token}`,
          },
        };

        const { data } = await axios.put(
          `${BASE_URL}/api/users/data/me`,
          {
            paymentLinks,
          },
          config
        );
        setPaymentLinks(data.paymentLinks);
        close();
      } catch (error) {
        console.log("Error", error);
        //handleErrors(error);
      }
    } else {
      alert("Fill all fields");
    }
  };

  const deleteContact = async () => {
    const paymentLinks = allPaymentLinks.filter(
      (o) => o._id !== paymentLink._id
    );
    console.log(paymentLinks);
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${currentUser.token}`,
        },
      };

      const { data } = await axios.put(
        `${BASE_URL}/api/users/data/me`,
        {
          paymentLinks,
        },
        config
      );
      setPaymentLinks(data.paymentLinks);
      close();
    } catch (error) {
      console.log("Error", error);
      //handleErrors(error);
    }
  };

  return (
    <div
      style={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <select
        className="inputControl"
        value={type}
        onChange={(e) => setType(e.target.value)}
      >
        <option value="">Pick</option>
        <option value="CASHAPP">CashApp</option>
        <option value="PAYPAL">Paypal</option>
        <option value="VENMO">Venmo</option>
        <option value="BITCOIN">Bitcoin</option>
        <option value="ETHEREUM">Ethereum</option>
      </select>
      <input
        className="inputControl"
        value={contact}
        placeholder="Url"
        onChange={(e) => setContact(e.target.value)}
      />
      <button className="saveButton" onClick={update}>
        {paymentLink.isEdit ? "Edit" : "Add"}
      </button>
      {paymentLink.isEdit && (
        <button className="saveButton" onClick={deleteContact}>
          Delete
        </button>
      )}
    </div>
  );
}

export default PaymentLinks;
