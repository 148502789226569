import React from "react";

export default function CalendlyIcon({ size, color = "#4691f6" }) {
  return (
    <div>
      <span className="icon-container">
        <svg 
        width={size === "big" ? "2em" : "1.6em"}
        height={size === "big" ? "2em" : "1.6em"}
        fill={color}
        viewBox="7.4 0 344.6 360">
          <path
            fill={color}
            d="m313.8 360h-268.3c-21 0-38.1-17.1-38.1-38.1v-268.4c0-21 17.1-38.1 38.1-38.1h268.3c21 0 38.1 17.1 38.1 38.1v268.3c.1 21.1-17 38.2-38.1 38.2zm-268.3-323.5c-9.4 0-17 7.6-17 17v268.3c0 9.4 7.6 17 17 17h268.3c9.4 0 17-7.6 17-17v-268.3c0-9.4-7.6-17-17-17z"
          ></path>
          <path
            fill={color}
            d="m256.6 72.4c-4.5 0-8.1-3.6-8.1-8.1v-56.2c0-4.5 3.6-8.1 8.1-8.1s8.1 3.6 8.1 8.1v56.1c0 4.5-3.6 8.2-8.1 8.2zm-154.7 0c-4.5 0-8.1-3.6-8.1-8.1v-56.2c0-4.5 3.6-8.1 8.1-8.1s8.1 3.6 8.1 8.1v56.1c.1 4.5-3.6 8.2-8.1 8.2zm87.5 181.4c-33.6 0-60.9-27.3-60.9-60.9s27.3-60.9 60.9-60.9c15.2 0 29.7 5.6 40.9 15.8 1.4 1.2 1.5 3.4.2 4.7-1.2 1.4-3.4 1.5-4.7.2-10-9.1-22.9-14.1-36.4-14.1-29.9 0-54.2 24.3-54.2 54.2s24.3 54.2 54.2 54.2c13.5 0 26.4-5 36.4-14.1 1.4-1.2 3.5-1.1 4.7.2 1.2 1.4 1.1 3.5-.2 4.7-11.2 10.4-25.7 16-40.9 16z"
          ></path>
        </svg>
      </span>
    </div>
  );
}

