import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { usePrestigiousContext } from "../../Context/appContext";
import EditIcon from "../../Icons/editIcon";
import PhoneIcon from "../../Icons/phoneIcon";
import MailIcon from "../../Icons/mailIcon";
import LocationIcon from "../../Icons/locationIcon";
import "./Contact.css";
import { BASE_URL } from "../../Constants/Constants";

function Contact({ history }) {
  const { logout, currentUser } = usePrestigiousContext();
  const [windowDimension, setWindowDimension] = useState(null);
  const [showMibileMenu, setShowMobileMenu] = useState(false);
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");

  useEffect(() => {
    setWindowDimension(window.innerWidth);
  }, []);

  useEffect(() => {
    function handleResize() {
      setWindowDimension(window.innerWidth);
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const isMobile = windowDimension <= 640;

  const sendMessage = async (e) => {
    e.preventDefault()
    console.log(name,
      email,
      phone,
      subject,
      message,)
      try {
        const { data } = await axios.post(
          `${BASE_URL}/api/contact`,
          {
            name,
            email,
            phone,
            subject,
            message,
          },
        );
        console.log(data);
        toast.success("Message Sended", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } catch (error) {
        console.log(error);
        const errorlog =
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message;
        console.log(errorlog);
        toast.error("Error " + errorlog, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
  };

  return (
    <div className="contactContainer">
      {isMobile ? (
        <div className="mobileMenuContainer">
          <div className="contactLogoWrapper">
            <div>
              <img
                alt="logo"
                className="contactMenuLogo"
                src={`${process.env.PUBLIC_URL + "/prestigiouslogo.png"}`}
              />
            </div>
            <h2 className="homeTitle">Prestigious</h2>
          </div>
          <span onClick={() => setShowMobileMenu(true)}>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24">
              <path d="M4 6h16v2H4zm0 5h16v2H4zm0 5h16v2H4z"></path>
            </svg>
          </span>
          {showMibileMenu ? (
            <div className="homeMenuMobile">
              <span
                className="homeMenuMobileClose"
                onClick={() => setShowMobileMenu(false)}
              >
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24">
                  <path d="m16.192 6.344-4.243 4.242-4.242-4.242-1.414 1.414L10.535 12l-4.242 4.242 1.414 1.414 4.242-4.242 4.243 4.242 1.414-1.414L13.364 12l4.242-4.242z"></path>
                </svg>
              </span>
              <div className="homeMobileLinks">
                <a href="/" className="topMenuLink">
                  <h3>Home</h3>
                </a>
                <a href="about" className="topMenuLink">
                  <h3> What's Prestigious?</h3>
                </a>
                <a href="/gallery" className="topMenuLink">
                  <h3>Coming Soon</h3>
                </a>
                <a href="/contact" className="topMenuLink">
                  <h3> Contact</h3>
                </a>
                {currentUser ? (
                  <>
                    {currentUser.isAdmin ? (
                      <a href="/admin" className="topMenuLink">
                        <h3>Admin</h3>
                      </a>
                    ) : (
                      <a href="/dashboard" className="topMenuLink">
                        <h3>Editor</h3>
                      </a>
                    )}
                    <span onClick={logout} className="topMenuLink textWhite">
                      <h3>Log out</h3>
                    </span>
                  </>
                ) : (
                  <>
                    <a href="/login" className="topMenuLink">
                      <h3>Login</h3>
                    </a>
                    <a href="/signup" className="topMenuLink">
                      <h3>Signup</h3>
                    </a>
                  </>
                )}
              </div>
            </div>
          ) : null}
        </div>
      ) : (
        <div className="contactTopMenu">
          <div className="contactLogoWrapper">
            <div>
              <img
                alt="logo"
                className="contactMenuLogo"
                src={`${process.env.PUBLIC_URL + "/prestigiouslogo.png"}`}
              />
            </div>
            <h2 className="homeTitle">Prestigious</h2>
          </div>
          <div className="contactMenuLinks">
            <a href="/" className="contactTopMenuLink">
              <h3>Home</h3>
            </a>
            <a href="/about" className="topMenuLink">
              <h3> What's Prestigious?</h3>
            </a>
            <a href="/gallery" className="topMenuLink">
              <h3>Coming Soon</h3>
            </a>
            <a href="/contact" className="topMenuLink">
              <h3> Contact</h3>
            </a>
          </div>
          <div>
            {currentUser ? (
              <div>
                {currentUser.isAdmin ? (
                  <button
                    onClick={() => history.push("/admin")}
                    className="homeButton"
                  >
                    Admin
                  </button>
                ) : (
                  <button
                    onClick={() => history.push("/dashboard")}
                    className="homeButton"
                  >
                    Editor
                  </button>
                )}
                <button onClick={logout} className="homeButton">
                  Log out
                </button>
              </div>
            ) : (
              <div>
                <button
                  onClick={() => history.push("/login")}
                  className="homeButton"
                >
                  Log In
                </button>
                <button
                  onClick={() => history.push("/signup")}
                  className="homeButton"
                >
                  Sign up
                </button>
              </div>
            )}
          </div>
        </div>
      )}

      <div
        style={{
          backgroundImage: `url(${process.env.PUBLIC_URL + "/contact.png"})`,
        }}
        className="contactHero"
      >
        <div className="contactHeroContent">
          <span>We can help you</span>
          <h2>Contact Us</h2>
        </div>
      </div>

      <section className="homeSection">
        <div className="homeFeatureContainer homeGrid clumn3Grid middleTextSizes">
          <div className="contactCard">
            <div className="contactCardIcon">
              <EditIcon size="big" color="#ad8457" />
            </div>
            <div className="contactCardInfo">
              <h3 className="featureCardText textWhite middleTextSize text-center">
                Office Address
              </h3>
              <span>1671 w katella ave ste 255, Anaheim CA 92802</span>
            </div>
          </div>
          <div className="contactCard">
            <div className="contactCardIcon">
              <PhoneIcon size="big" color="#ad8457" />
            </div>
            <div className="contactCardInfo">
              <h3 className="featureCardText textWhite middleTextSize text-center">
                Phone Number
              </h3>
              <span>+1 714 410 91417</span>
            </div>
          </div>
          <div className="contactCard">
            <div className="contactCardIcon">
              <MailIcon size="big" color="#ad8457" />
            </div>
            <div className="contactCardInfo">
              <h3 className="featureCardText textWhite middleTextSize text-center">
                Email Address
              </h3>
              <span>info@prestigeous.com</span>
            </div>
          </div>
        </div>
      </section>
      <div className="contactMapWrapper">
        <img
          alt="mapa"
          className="contactMap"
          src={`${process.env.PUBLIC_URL + "/mapa.jpg"}`}
        />
      </div>


      <form  onSubmit={sendMessage} className="contactFormWrapper">
        <div className="formInput">
          <input
          required
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder="Name"
          />
        </div>
        <div className="formInput">
          <input
          required
            type="text"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Email"
          />
        </div>
        <div className="formInput">
          <input
          required
            type="text"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            placeholder="Phone"
          />
        </div>
        <div className="formInput">
          <input
          required
            type="text"
            value={subject}
            onChange={(e) => setSubject(e.target.value)}
            placeholder="Subject"
          />
        </div>
        <div className="formInput fortmTextarea">
          <textarea
          required
            type="text"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            placeholder="Message"
          />
          <br />
          <br />
          <button type="submit">Send</button>
        </div>
      </form>

      <footer className="homeSection pb-155 footerContainer">
        <div className="footerVision">
          <img
            className="smallIcon"
            src={`${process.env.PUBLIC_URL + "/prestigiouslogo.png"}`}
            alt="logo"
          />
          <span>
            Presence, prestige, and perfection are what you'll find with our
            most exclusive product - premium, innovative, and elegant. There's
            only one prestigious.
          </span>
        </div>

        <div className="footerMenu">
          <h2 className="textWhite">Site Map</h2>
          <div className="footerMenuLinks">
            <div className="footerMenuLinksColumn">
              <div className="footerLink">
                <a href="/">Home</a>
              </div>
              <div className="footerLink">
                <a href="/about">What's Prestigious?</a>
              </div>
            </div>
            <div className="footerMenuLinksColumn">
              <div className="footerLink">
                <a href="/gallery">Coming Soon</a>
              </div>
              <div className="footerLink">
                <a href="/contact">Contact</a>
              </div>
            </div>
          </div>
        </div>

        <div className="footerContactContainer">
          <h2 className="textWhite">Contact Us</h2>
          <div className="footerContactCard">
            <div className="featuredCardIcon">
              <PhoneIcon size="big" color="#ad8457" />
            </div>
            <div className="footerInfoWrapper">
              <h3 className=" textWhite middleTextSize text-center">
                Phone Number
              </h3>
              <br />
              <span>+714 410 9141</span>
            </div>
          </div>
          <div className="footerContactCard">
            <div className="featuredCardIcon">
              <MailIcon size="big" color="#ad8457" />
            </div>
            <div className="footerInfoWrapper">
              <h3 className=" textWhite middleTextSize text-center">
                Email Address
              </h3>
              <br />
              <span>info@prestigeous.com</span>
            </div>
          </div>
          <div className="footerContactCard">
            <div className="featuredCardIcon">
              <LocationIcon size="big" color="#ad8457" />
            </div>
            <div className="footerInfoWrapper">
              <h3 className=" textWhite middleTextSize text-center">
                Office Address
              </h3>
              <br />
              <span>1671 w katella ave ste 255, Anaheim CA 92802</span>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default Contact;
