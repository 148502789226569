import axios from "axios";
import { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import moment from "moment";
import { useTitle } from "react-use";
import { toast } from "react-toastify";
import { BASE_URL } from "../../Constants/Constants";
import { usePrestigiousContext } from "../../Context/appContext";
import "./Admin.css";

function Users({ match, history }) {
  const [users, setUsers] = useState(null);
  const [errors, setErrors] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");



  const keyword = match.params.keyword;
  const pageNumber = match.params.pageNumber || 1;

  useTitle("Prestigious | Users");

  const { currentUser, activeUser } = usePrestigiousContext();

  useEffect(() => {
    getUsers(keyword, pageNumber);
  }, [keyword, pageNumber]);

  const getUsers = async (keyword = "", pageNumber = "") => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${currentUser.token}`,
        },
      };
      const { data } = await axios.get(
        `${BASE_URL}/api/users?keyword=${keyword}&pageNumber=${pageNumber}`,
        config
      );
      //console.log(data);
      //setInputs(data.users.)
      setUsers(data);
    } catch (error) {
      console.log(error);
      setErrors(error);
    }
  };

  const submitHandler = (e) => {
    e.preventDefault();
    if (searchTerm.trim()) {
      history.push(`/admin/users/search/${searchTerm}`);
    } else {
      history.push("/admin/users");
    }
  };

  const handleChange = (event) => {
    const value = event.selected + 1;
    if (keyword) {
      history.push(`/admin/users/search/${keyword}/page/${value}`);
    } else {
      history.push(`/admin/users/page/${value}`);
    }
  };

  const assignId = async (generate, id, cardsId, addNew) => {
    //const cardId = users.users[index].cardId;
    await assignIdCard(generate, id, cardsId, addNew);
    await getUsers(keyword, pageNumber);
  };

  const assignIdCard = async (generate, id, cardsId, addNew) => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${currentUser.token}`,
        },
      };

      const { data } = await axios.put(
        `${BASE_URL}/api/users/card/${id}`,
        { generate, cardsId, addNew },
        config
      );
      toast.success("Updated", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      //console.log(data);
    } catch (error) {
      console.log("Error", error);
      const errorlog =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      console.log(errorlog);
      toast.error("Error " + errorlog, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      //handleErrors(error);
    }
  };

  const activateUser = async (active, id) => {
    const data = { active: !active ? 1 : 2 };
    await activeUser(data, id);
    toast("Updated", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    await getUsers(keyword, pageNumber);
  };

  const onChange = (e, userId, index) => {
   const values = [...users.users];
   values[index].tempId = e.target.value;
   setUsers({ ...users, users: values });
  };

  const onKeyDown = async (e, userId, index) => {
    const { key } = e;
    const values = [...users.users];
    const trimmedInput = values[index].tempId?.trim();

    if (
      key === "Enter" &&
      trimmedInput.length
    ) {
      e.preventDefault();
      const data = [...values[index].cardsId, trimmedInput]
      values[index].tempId = "";
      setUsers({ ...users, users: values });
      await assignId(false, userId, data, true)
    }

   /* if (key === "Backspace" && !input.length && tags.length && isKeyReleased) {
      const tagsCopy = [...tags];
      const poppedTag = tagsCopy.pop();
      e.preventDefault();
      setTags(tagsCopy);
      setInput(poppedTag);
    }*/
  };


  const deleteTag = async (tagIndex, userId, index) => {
    const values = [...users.users];
    const data = values[index].cardsId.filter((tag, i) => i !== tagIndex);
    await assignId(false, userId, data, false)
  };

  if (errors) return <div>Error {errors}</div>;

  if (!users) return <div>Cargando...</div>;

  return (
    <div
      className="adminContainer"
      style={{
        backgroundImage: `url(${process.env.PUBLIC_URL + "/background.png"})`,
      }}
    >
      <div className="adminheaderContainer">
        <h2 className="pageTitle">Users</h2>
        <div className="adminSearchWrapper">
          <input
            className="inputControl"
            placeholder="Search"
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <button className="adminBtn" onClick={submitHandler}>
            Search
          </button>
        </div>
      </div>
      <div className="adminTableContainer">
        <table className="adminTable">
          <thead className="adminTableHead">
            <tr className="adminTableRow">
              <th className="adminTableCellHead adminTableCell ">Date</th>
              <th className="adminTableCellHead adminTableCell ">Name</th>
              <th className="adminTableCellHead adminTableCell">Email</th>
              <th className="adminTableCellHead adminTableCell">Views</th>
              <th className="adminTableCellHead adminTableCell">Active</th>
              <th className="adminTableCellHead adminTableCell">Card Id</th>
              <th className="adminTableCellHead adminTableCell">Assingn</th>
              <th className="adminTableCellHead adminTableCell">Profile</th>
              <th className="adminTableCellHead adminTableCell">Manage</th>
              <th className="adminTableCellHead adminTableCell">Actions</th>
            </tr>
          </thead>
          <tbody className="adminTableBody">
            {users.users.map((user, index) => {
              return (
                <tr className="adminTableRow" key={user._id}>
                  <td className="adminTableCell adminTableCellBody">
                    {moment(user.createdAt).format("DD/MM/YY hh:mm a")}
                  </td>
                  <td className="adminTableCell adminTableCellBody">
                    {user.name}
                  </td>
                  <td className="adminTableCell adminTableCellBody">
                    {user.email}
                  </td>
                  <td className="adminTableCell adminTableCellBody">
                    {user.profileViews}
                  </td>
                  <td className="adminTableCell adminTableCellBody">
                    {user.active ? "Yes" : "No"}
                  </td>
                  <td className="adminTableCell adminTableCellBody">
                    <div className="adminTagsContainer">
                      {user.cardsId.map((tag, tagIndex) => (
                        <div className="adminTag" key={tagIndex}>
                          {tag}
                          <button className="adminTagButton" onClick={() => deleteTag(tagIndex, user._id, index)}>x</button>
                        </div>
                      ))}
                    </div>
                  </td>
                  <td className="adminTableCell adminTableCellBody">
                    <input
                      className="adminTagsInput"
                      value={user.tempId}
                      placeholder="Enter Id"
                      onKeyDown={(e) => onKeyDown(e, user._id, index)}
                      onChange={(e) => onChange(e, user._id, index)}
                    />
                  </td>
                  <td className="adminTableCell adminTableCellBody">
                   {user.cardsId.length ? <a
                      className="adminBtn"
                      href={`/card/${user.cardsId[0]}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Open
                    </a> : <span className="adminBtn">Not Id</span>}
                  </td>
                  <td className="adminTableCell adminTableCellBody">
                    <button
                      className="adminBtn"
                      onClick={() => activateUser(user.active, user._id)}
                    >
                      {user.active ? "Block" : "Active"}
                    </button>
                  </td>
                  <td className="adminTableCell adminTableCellBody">
                    <a
                      className="adminBtn"
                      href={`/admin/users/${user._id}`}
                      rel="noopener noreferrer"
                    >
                      Detailed
                    </a>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <ReactPaginate
        previousLabel="Previous"
        nextLabel="Next"
        pageClassName="adminPageWrapper"
        pageLinkClassName="adminPageItem"
        previousClassName="adminPageWrapper"
        previousLinkClassName="adminPageItem"
        nextClassName="adminPageWrapper"
        nextLinkClassName="adminPageItem"
        breakLabel="..."
        breakClassName="adminPageWrapper"
        breakLinkClassName="adminPageItem"
        pageCount={users.pages}
        marginPagesDisplayed={2}
        pageRangeDisplayed={5}
        onPageChange={handleChange}
        containerClassName="adminPagination"
        activeClassName="adminActiveItem"
        activeLinkClassName="adminActiveItem"
        //forcePage={pageNumber}
      />
    </div>
  );
}

export default Users;
