import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { PrestigiousProvider } from "./Context/appContext";
import Home from "./Screens/Home/Home";
import Contact from "./Screens/Contact/Contact";
import WhatsPrestigious from "./Screens/WhatsPrestigious/WhatsPrestigious";
import ComingSoon from "./Screens/ComingSoon/ComingSoon";
import Dashboard from "./Screens/Dashboard/Dashboard";
import Login from "./Screens/Login/Login";
import AdminLogin from "./Screens/Admin/Login";
import Profile from "./Screens/Profile/Profile";
import Signup from "./Screens/Signup/Signup";
import Admin from "./Screens/Admin/Admin";
import Users from "./Screens/Admin/Users";
import Messages from "./Screens/Admin/Messages";
import Orders from "./Screens/Admin/Orders";
import DetailedOrder from "./Screens/Admin/DetailedOrder";
import DetailedUser from "./Screens/Admin/DetailedUser";
import NewOrder from "./Screens/NewOrder/NewOrder";
import EnterPassword from "./Screens/PasswordRecover/EnterPassword";
import RecoverPassword from "./Screens/PasswordRecover/RecoverPassword";
import AuthRoute from "./Components/AuthRoute";
import PrivateRoute from "./Components/PrivateRoute";
import AuthAdminRoute from "./Components/AuthAdminRoute";
import PrivateAdminRoute from "./Components/PrivarteAdminRoute";
import "react-toastify/dist/ReactToastify.css";
//10QZ429
function App() {
  return (
    <div>
      <Router>
        <PrestigiousProvider>
          <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/contact" component={Contact} />
            <Route exact path="/about" component={WhatsPrestigious} />
            <Route exact path="/gallery" component={ComingSoon} />
            <AuthRoute path="/login" component={Login} />
            <AuthRoute path="/signup" component={Signup} />
            <AuthRoute path="/recover-password" component={RecoverPassword} />
            <AuthRoute path="/new-password/:token" component={EnterPassword} />
            <PrivateRoute path="/dashboard" component={Dashboard} />
            <Route path="/buy" component={NewOrder} />
            <PrivateAdminRoute exact path="/admin" component={Admin} />
            <PrivateAdminRoute exact path="/admin/orders" component={Orders} />
            <PrivateAdminRoute
              path="/admin/orders/search/:keyword"
              component={Orders}
              exact
            />
            <PrivateAdminRoute
              path="/admin/orders/page/:pageNumber"
              component={Orders}
              exact
            />
            <PrivateAdminRoute
              path="/admin/orders/search/:keyword/page/:pageNumber"
              component={Orders}
              exact
            />
            <PrivateAdminRoute
              exact
              path="/admin/orders/:id"
              component={DetailedOrder}
            />
            <PrivateAdminRoute exact path="/admin/users" component={Users} />
            <PrivateAdminRoute
              path="/admin/users/search/:keyword"
              component={Users}
              exact
            />
            <PrivateAdminRoute
              path="/admin/users/page/:pageNumber"
              component={Users}
              exact
            />
            <PrivateAdminRoute
              path="/admin/users/search/:keyword/page/:pageNumber"
              component={Users}
              exact
            />

            <PrivateAdminRoute exact path="/admin/messages" component={Messages} />
            <PrivateAdminRoute
              path="/admin/messages/search/:keyword"
              component={Messages}
              exact
            />
            <PrivateAdminRoute
              path="/admin/messages/page/:pageNumber"
              component={Messages}
              exact
            />
            <PrivateAdminRoute
              path="/admin/messages/search/:keyword/page/:pageNumber"
              component={Messages}
              exact
            />
            <AuthAdminRoute exact path="/admin/login" component={AdminLogin} />
            <Route path="/card/:id" component={Profile} />
          </Switch>
        </PrestigiousProvider>
      </Router>
      <ToastContainer theme="dark" />
    </div>
  );
}

export default App;
