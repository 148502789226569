import React, { useContext, useState, useEffect } from "react";
import axios from "axios";
import jwtDecode from "jwt-decode";
import { BASE_URL } from "../Constants/Constants";

const PrestigiousContext = React.createContext();

export function usePrestigiousContext() {
  return useContext(PrestigiousContext);
}

export function PrestigiousProvider({ children }) {
  const [loading, setLoading] = useState(true);
  const [currentUser, setCurrentUser] = useState(null);
  const [errors, setErrors] = useState(null);

  const register = async (name, email, password) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const { data } = await axios.post(
        `${BASE_URL}/api/users`,
        { name, email, password },
        config
      );
      setCurrentUser(data);
      localStorage.setItem("prestUser", JSON.stringify(data));
    } catch (error) {
      console.log("Error", error);
      handleErrors(error);
    }
  };

  const login = async (email, password) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const { data } = await axios.post(
        `${BASE_URL}/api/users/login`,
        { email, password },
        config
      );
      setCurrentUser(data);
      localStorage.setItem("prestUser", JSON.stringify(data));
    } catch (error) {
      console.log("Error", error);
      handleErrors(error);
    }
  };

  const adminLogin = async (email, password) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const { data } = await axios.post(
        `${BASE_URL}/api/admin/login`,
        { email, password },
        config
      );
      setCurrentUser(data);
      localStorage.setItem("prestUser", JSON.stringify(data));
    } catch (error) {
      console.log("Error", error);
      handleErrors(error);
    }
  };

  const logout = async () => {
    if (localStorage.getItem("prestUser")) {
      localStorage.removeItem("prestUser");
    }
    setCurrentUser(null);
  };

  const updateUser = async (body) => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${currentUser.token}`,
        },
      };

      const { data } = await axios.put(
        `${BASE_URL}/api/users/data/me`,
        body,
        config
      );
      console.log(data);
    } catch (error) {
      console.log("Error", error);
      handleErrors(error);
    }
  };

  const activeUser = async (body, id) => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${currentUser.token}`,
        },
      };

      const { data } = await axios.put(
        `${BASE_URL}/api/users/${id}`,
        body,
        config
      );
      console.log(data);
    } catch (error) {
      console.log("Error", error);
      handleErrors(error);
    }
  };

  const assignIdCard = async (id, cardId) => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${currentUser.token}`,
        },
      };

      const { data } = await axios.put(
        `${BASE_URL}/api/users/card/${id}`,
        {cardId},
        config
      );
      console.log(data);
    } catch (error) {
      console.log("Error", error);
      handleErrors(error);
    }
  };

  const getOrders = async (keyword = "", pageNumber = "") => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${currentUser.token}`,
        },
      };
      const { data } = await axios.get(
        `${BASE_URL}/api/order?keyword=${keyword}&pageNumber=${pageNumber}`,
        config
      );
      return data;
    } catch (error) {
      console.log(error);
      handleErrors(error);
    }
  };

  const getOrderById = async (id) => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${currentUser.token}`,
        },
      };
      const { data } = await axios.get(
        `${BASE_URL}/api/order/${id}`,
        config
      );
      return data;
    } catch (error) {
      handleErrors(error);
    }
  };

  const createOrder = async (order) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const { data } = await axios.post(
        `${BASE_URL}/api/order/`,
        order,
        config
      );
      return data;
    } catch (error) {
      console.log(error);
      handleErrors(error);
    }
  };

  const getUsers = async (keyword = "", pageNumber = "") => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${currentUser.token}`,
        },
      };
      const { data } = await axios.get(
        `${BASE_URL}/api/users?keyword=${keyword}&pageNumber=${pageNumber}`,
        config
      );
      return data;
    } catch (error) {
      console.log(error);
      handleErrors(error);
    }
  };

  const handleErrors = (error) =>{
    const errorlog = error.response && error.response.data.message
    ? error.response.data.message
    : error.message;
    console.log(errorlog)
    setErrors(errorlog);
  }

  useEffect(() => {
    if (localStorage.getItem("prestUser")) {
      const user = JSON.parse(localStorage.getItem("prestUser"));
      const decodedToken = jwtDecode(user.token);

      if (decodedToken.exp * 1000 < Date.now()) {
        localStorage.removeItem("prestUser");
      } else {
        setCurrentUser(user);
      }
    }
    setLoading(false);
  }, []);

  const value = {
    currentUser,
    register,
    login,
    adminLogin,
    logout,
    updateUser,
    activeUser,
    assignIdCard,
    getOrders,
    getOrderById,
    createOrder,
    getUsers,
    errors
  };
  return (
    <PrestigiousContext.Provider value={value}>
      {!loading && children}
    </PrestigiousContext.Provider>
  );
}
