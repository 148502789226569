import { useState } from "react";
import axios from "axios";
import { toast } from 'react-toastify';
import { BASE_URL } from "../../Constants/Constants";

import "./RecoverPassword.css";

function RecoverPassword() {
  const [email, setEmail] = useState("");



  const handleClick = async () => {
    try {
        const config = {
          headers: {
            "Content-Type": "application/json",
          },
        };
  
        const { data } = await axios.post(
          `${BASE_URL}/api/password-reset`,
          {email},
          config
        );
        console.log(data)
        setEmail("")
        toast.success('Check your email', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            });

      } catch (error) {
        console.log("Error", error);
        const errorlog = error.response && error.response.data.message
    ? error.response.data.message
    : error.message;
    console.log(errorlog)
      toast.error('Error '+ errorlog, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        });
      }

   
  };
    return (
      <div
      className="loginContainer"
      style={{
        backgroundImage: `url(${process.env.PUBLIC_URL + "/background.png"})`,
      }}
    >
      <img alt="logo" className="logo" src={`${process.env.PUBLIC_URL + "/prestigiouslogo.png"}`} />
      <h1 className="titleText">Prestigious</h1>
      <div className="inputControlWrapper">
        <input
          className="inputField"
          type="text"
          placeholder="Email"
          onChange={(e) => setEmail(e.target.value)}
        />
      </div>
      <button onClick={handleClick} className="input-submit-button">
        Submit
      </button>
    </div>
    );
  }
  
  export default RecoverPassword;