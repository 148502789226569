import { useEffect, useState } from "react";
import axios from "axios";
import { usePrestigiousContext } from "../../../../Context/appContext";
import { BASE_URL } from "../../../../Constants/Constants";

function SocialLinks({
  match,
  history,
  socialLink,
  allSocialLinks,
  setSocialLinks,
  close,
}) {
  const [name, setName] = useState(socialLink.name);
  const [link, setLink] = useState(socialLink.link);
  const [type, setType] = useState(socialLink.type);

  const { currentUser } = usePrestigiousContext();

  useEffect(() => {}, []);

  const update = async () => {
    if (link && type) {
      let socialLinks = allSocialLinks;
      if (socialLink.isEdit) {
        const index = allSocialLinks.findIndex((o) => o._id === socialLink._id);
        socialLinks[index].name = name;
        socialLinks[index].link = link;
        socialLinks[index].type = type;
      } else {
        socialLinks = [
          ...allSocialLinks,
          {
            name,
            link,
            type,
          },
        ];
      }
      try {
        const config = {
          headers: {
            Authorization: `Bearer ${currentUser.token}`,
          },
        };

        const { data } = await axios.put(
          `${BASE_URL}/api/users/data/me`,
          {
            socialLinks,
          },
          config
        );
        setSocialLinks(data.socialLinks);
        close();
      } catch (error) {
        console.log("Error", error);
        //handleErrors(error);
      }
    } else {
      alert("Fill all fields");
    }
  };

  const deleteContact = async () => {
    const socialLinks = allSocialLinks.filter((o) => o._id !== socialLink._id);
    //console.log(socialLinks);
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${currentUser.token}`,
        },
      };

      const { data } = await axios.put(
        `${BASE_URL}/api/users/data/me`,
        {
          socialLinks,
        },
        config
      );
      setSocialLinks(data.socialLinks);
      close();
    } catch (error) {
      console.log("Error", error);
      //handleErrors(error);
    }
  };

  return (
    <div
      style={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <select
        className="inputControl"
        value={type}
        onChange={(e) => setType(e.target.value)}
      >
        <option value="">Pick</option>
        <option value="FACEBOOK">Facebook</option>
        <option value="TWITTER">Twitter</option>
        <option value="INSTAGRAM">Instagram</option>
        <option value="TWITCH">Twitch</option>
        <option value="YOUTUBE">Youtube</option>
        <option value="LNKIN">Linked In</option>
        <option value="TIKTOK">Tik Tok</option>
      </select>
      {/*<input className="inputControl" placeholder="Name" value={name} onChange={e => setName(e.target.value)} />*/}
      <input
        className="inputControl"
        placeholder="Url"
        value={link}
        onChange={(e) => setLink(e.target.value)}
      />
      <button className="saveButton" onClick={update}>
        {socialLink.isEdit ? "Edit" : "Add"}
      </button>
      {socialLink.isEdit && (
        <button className="saveButton" onClick={deleteContact}>
          Delete
        </button>
      )}
    </div>
  );
}

export default SocialLinks;
