import React from "react";
import { Route, Redirect } from "react-router-dom";
import { usePrestigiousContext } from "../Context/appContext";

export default function AuthRoute({ component: Component, ...rest }) {
  const { currentUser } = usePrestigiousContext();

  return (
    <Route
      {...rest}
      render={props => {
        return currentUser ? <Redirect to="/" /> : <Component {...props} />
      }}
    ></Route>
  );
};
