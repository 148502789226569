import { useState } from "react";
import { usePrestigiousContext } from "../../Context/appContext";

import "./Signup.css";

function Signup() {
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { register, errors } = usePrestigiousContext();

  const handleClick = (e) => {
    e.preventDefault();
    register(username, email, password);
  };
  return (
    <div
      className="loginContainer"
      style={{
        backgroundImage: `url(${process.env.PUBLIC_URL + "/background.png"})`,
      }}
    >
      <img alt="logo" className="logo" src={`${process.env.PUBLIC_URL + "/prestigiouslogo.png"}`} />
      <h1 className="titleText">Prestigious</h1>
      <div className="inputControlWrapper">
        <input
          className="inputField"
          type="text"
          placeholder="Username"
          onChange={(e) => setUsername(e.target.value)}
        />
      </div>
      <div className="inputControlWrapper">
        <input
          className="inputField"
          type="email"
          placeholder="Email"
          onChange={(e) => setEmail(e.target.value)}
        />
      </div>
      <div className="inputControlWrapper">
        <input
          className="inputField"
          type="Password"
          placeholder="password"
          onChange={(e) => setPassword(e.target.value)}
        />
      </div>
      {errors && <h3 style={{color: "#fff", padding: "10px"}}>{errors}</h3>}
      <button onClick={handleClick} className="input-submit-button">
        Signup
      </button>
    </div>
  );
}

export default Signup;
