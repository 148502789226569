import axios from "axios";
import { useEffect, useState } from "react";
import ReactPaginate from 'react-paginate';
import moment from "moment";
import {useTitle} from 'react-use';
import { BASE_URL } from "../../Constants/Constants";
import { usePrestigiousContext } from "../../Context/appContext";
import "./Admin.css";


function Orders({ match, history }) {
  const [orders, setOrders] = useState(null);
  const [errors, setErrors] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const keyword = match.params.keyword;
  const pageNumber = match.params.pageNumber || 1;

  useTitle('Prestigious | Orders');

  const { currentUser } = usePrestigiousContext()


  useEffect(()=>{
      getOrders(keyword, pageNumber)
  },[keyword, pageNumber])

  const getOrders = async (keyword = "", pageNumber = "") => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${currentUser.token}`,
        },
      };
      const { data } = await axios.get(
        `${BASE_URL}/api/order?keyword=${keyword}&pageNumber=${pageNumber}`,
        config
      );
      //console.log(data)
      setOrders(data);
    } catch (error) {
      console.log(error)
      setErrors(error)
      
    }
  };

  const submitHandler = (e) => {
    e.preventDefault();
    if (searchTerm.trim()) {
      history.push(`/admin/orders/search/${searchTerm}`);
    } else {
      history.push("/admin/orders");
    }
  };

  const handleChange = (event) => {
    const value = (event.selected) + 1;
    if (keyword) {
      history.push(`/admin/orders/search/${keyword}/page/${value}`);
    } else {
      history.push(`/admin/orders/page/${value}`);
    }
  };

 
  if (errors) return <div>Error {errors}</div>;

  if (!orders) return <div>Cargando...</div>;

    return (
      <div className="adminContainer" style={{
        backgroundImage: `url(${
          process.env.PUBLIC_URL + "/background.png"
        })`,
      }}>
        <div className="adminheaderContainer">
          <h2 className="pageTitle">Orders</h2>
          <div className="adminSearchWrapper">
            <input className="inputControl" placeholder="Search" onChange={(e) => setSearchTerm(e.target.value)} />
            <button className="adminBtn" onClick={submitHandler}>Search</button>
          </div>         
        </div>
        <div className="adminTableContainer"> 
        <table className="adminTable">
          <thead className="adminTableHead">
          <tr className="adminTableRow">
             <th className="adminTableCellHead adminTableCell ">Date</th>
              <th className="adminTableCellHead adminTableCell ">Name</th>
              <th className="adminTableCellHead adminTableCell">Email</th>
              <th className="adminTableCellHead adminTableCell">Phone</th>
              <th className="adminTableCellHead adminTableCell ">Address</th>
              <th className="adminTableCellHead adminTableCell">City</th>
              <th className="adminTableCellHead adminTableCell ">State</th>
              <th className="adminTableCellHead adminTableCell">Zip Code</th>
              <th className="adminTableCellHead adminTableCell ">Print Name</th>
              <th className="adminTableCellHead adminTableCell">Status</th>
            </tr>
          </thead>
          <tbody className="adminTableBody">
          {orders.orders.map((order) => {
              return (
                <tr className="adminTableRow" key={order._id}>
                  <td className="adminTableCell adminTableCellBody">
                  {moment(order.createdAt).format("DD/MM/YY hh:mm a")}
                  </td>
                  <td className="adminTableCell adminTableCellBody">
                   {order.user_name}
                  </td>
                  <td className="adminTableCell adminTableCellBody">
                   {order.user_email}
                  </td>
                  <td className="adminTableCell adminTableCellBody">
                   {order.user_phone}
                  </td>
                  <td className="adminTableCell adminTableCellBody">
                    {order.delivery_address}
                  </td>
                  <td className="adminTableCell adminTableCellBody">
                    {order.delivery_city}
                  </td>
                  <td className="adminTableCell adminTableCellBody">
                   {order.delivery_state}
                  </td>
                  <td className="adminTableCell adminTableCellBody">
                   {order.delivery_zipcode}
                  </td>
                  <td className="adminTableCell adminTableCellBody">
                    {order.print_text}
                  </td>
                  <td className="adminTableCell adminTableCellBody">
                    {order.status}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        </div>
        <ReactPaginate
        previousLabel="Previous"
        nextLabel="Next"
        pageClassName="adminPageWrapper"
        pageLinkClassName="adminPageItem"
        previousClassName="adminPageWrapper"
        previousLinkClassName="adminPageItem"
        nextClassName="adminPageWrapper"
        nextLinkClassName="adminPageItem"
        breakLabel="..."
        breakClassName="adminPageWrapper"
        breakLinkClassName="adminPageItem"
        pageCount={orders.pages}
        marginPagesDisplayed={2}
        pageRangeDisplayed={5}
        onPageChange={handleChange}
        containerClassName="adminPagination"
        activeClassName="adminActiveItem"
        activeLinkClassName="adminActiveItem"
        //forcePage={pageNumber}
      />
      </div>
    );
  }
  
  export default Orders;