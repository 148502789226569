import { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { BASE_URL } from "../../Constants/Constants";

import "./RecoverPassword.css";

function EnterPassword({ match, history }) {
  const [confirmPassword, setConfirmPassword] = useState("");
  const [password, setPassword] = useState("");
  const [found, setFound] = useState(false);
  const [loading, setLoading] = useState(true);
  const token = match.params.token;

  useEffect(() => {
    getToken();
  }, [token]);

  const getToken = async () => {
    setLoading(true);
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const { data } = await axios.get(
        `${BASE_URL}/api/password-reset/${token}`,
        config
      );
      console.log("token", data);
      setFound(data.found);
    } catch (error) {
      console.log("Error", error);
    }
    setLoading(false);
  };

  const handleClick = async () => {
    if (password === confirmPassword) {
      try {
        const config = {
          headers: {
            "Content-Type": "application/json",
          },
        };

        const { data } = await axios.post(
          `${BASE_URL}/api/password-reset/${token}`,
          { password },
          config
        );
        console.log(data);
        toast.success("Updated successfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } catch (error) {
        console.log("Error", error);
        const errorlog =
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message;
        console.log(errorlog);
        toast.error("Error " + errorlog, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } else {
      toast.error("Password not match", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  if (loading)return <div>Loading...</div>;

  if (!found)return <div>Invalid Request</div>;

  return (
    <div
    className="loginContainer"
    style={{
      backgroundImage: `url(${process.env.PUBLIC_URL + "/background.png"})`,
    }}
    >
      <img alt="logo" className="logo" src={`${process.env.PUBLIC_URL + "/prestigiouslogo.png"}`} />
      <h1 className="titleText">Prestigious</h1>
      <div className="inputControlWrapper">
      <input
        className="inputField"
        type="password"
        placeholder="New Password"
        onChange={(e) => setPassword(e.target.value)}
      />
      </div>
      <div className="inputControlWrapper">
      <input
        className="inputField"
        type="password"
        placeholder="Confirm"
        onChange={(e) => setConfirmPassword(e.target.value)}
      />
       </div>
      <button onClick={handleClick} className="input-submit-button">
        Submit
      </button>
    </div>
  );
}

export default EnterPassword;
