import { useState } from "react";
import { usePrestigiousContext } from "../../Context/appContext";

import "./Login.css";
 
function Login({history}) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { login, errors } = usePrestigiousContext();

  const handleClick = (e) => {
    e.preventDefault();
    login(email, password);
  };
  return (
    <div
      className="loginContainer"
      style={{
        backgroundImage: `url(${process.env.PUBLIC_URL + "/background.png"})`,
      }}
    >
      <img alt="logo" className="logo" src={`${process.env.PUBLIC_URL + "/prestigiouslogo.png"}`} />
      <h1 className="titleText">Prestigious</h1>
      <div className="inputControlWrapper">
        <input
          className="inputField"
          type="text"
          placeholder="Email"
          onChange={(e) => setEmail(e.target.value)}
        />
      </div>
      <div className="inputControlWrapper">
        <input
          className="inputField"
          type="password"
          placeholder="password"
          onChange={(e) => setPassword(e.target.value)}
        />
      </div>
      {errors && <h3 style={{color: "#fff", padding: "10px"}}>{errors}</h3>}
      <button onClick={handleClick} className="input-submit-button">
        Login
      </button>
      <button onClick={() => history.push("/recover-password")} className="input-submit-button" style={{marginTop: "15px"}}>
        Forgot Password?
      </button>
      <button onClick={() => history.push("/signup")} className="input-submit-button" style={{marginTop: "15px"}}>
        Sign up
      </button>
    </div>
  );
}

export default Login;
