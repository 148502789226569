import { useEffect, useState } from "react";
import moment from "moment";
import { usePrestigiousContext } from "../../Context/appContext";
import "./Admin.css";


function Orders({ match, history }) {
  const [order, setOrder] = useState(null);
  const id = match.params.id;

  const { getOrderById, error } = usePrestigiousContext()


  useEffect(() => {
    getOrder(id);
  }, [id]);

  const getOrder = async (id) => {
    const order = await getOrderById(id);
    setOrder(order);
  };


 
  if (error) return <div>Error {error}</div>;

  if (!order) return <div>Cargando...</div>;

    return (
      <div>
          <h3>Date: {moment(order.createdAt).format("DD/MM/YY hh:mm a")}</h3>
          <h3>Name: {order.user_name}</h3>
          <h3>Phone: {order.user_phone}</h3>
          <h3>Email: {order.user_email}</h3>
          <h3>Delivery Address: {order.delivery_address}</h3>
          <h3>Delivery Instructions: {order.delivery_instructions}</h3>
          <h3>City: {order.delivery_city}</h3>
          <h3>State: {order.delivery_state}</h3>
          <h3>Zip Code: {order.delivery_zipcode}</h3>
          <h3>Print Text: {order.print_text}</h3>
          <h3>Subtotal: {order.sub_total}</h3>
          <h3>Total: {order.total_price}</h3>
      </div>
    );
  }
  
  export default Orders;