import { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { useTitle, useCopyToClipboard } from "react-use";
import { BASE_URL } from "../../Constants/Constants";
import { Swiper, SwiperSlide } from "swiper/react";
import ReactPlayer from "react-player";
import QRCode from "react-qr-code";
import { BottomSheet } from "react-spring-bottom-sheet";
import MailIcon from "../../Icons/mailIcon";
import TelegramIcon from "../../Icons/telegramIcon";
import MessageIcon from "../../Icons/messageIcon";
import PhoneIcon from "../../Icons/phoneIcon";
import LocationIcon from "../../Icons/locationIcon";
import ShareIcon from "../../Icons/shareIcon";
import AddContactIcon from "../../Icons/addContactIcon";
import WhatsappIcon from "../../Icons/whatsappIcon";
import FacebookIcon from "../../Icons/SocialIcons/facebookIcon";
import TwitterIcon from "../../Icons/SocialIcons/twitterIcon";
import TwitcIcon from "../../Icons/SocialIcons/twitchIcon";
import InstaIcon from "../../Icons/SocialIcons/instaIcon";
import YoutubeIcon from "../../Icons/SocialIcons/youtubeIcon";
import LinkedinIcon from "../../Icons/SocialIcons/linkedinIcon";
import TikTokIcon from "../../Icons/SocialIcons/tiktokIcon";
import SpotifyIcon from "../../Icons/MusicIcons/spotifyIcon";
import SoundCloudIcon from "../../Icons/MusicIcons/soundcloudIcon";
import AppleMusicIcon from "../../Icons/MusicIcons/applemusicIcon";
import YoutubeMusicIcon from "../../Icons/MusicIcons/youtubemusicIcon";
import TidalIcon from "../../Icons/MusicIcons/tidalIcon";
import CalendlyIcon from "../../Icons/calendlyIcon";
import YelpIcon from "../../Icons/yelpIcon";
import EtsyIcon from "../../Icons/etsyIcon";
import CashappIcon from "../../Icons/PaymentIcons/cashappIcon";
import PaypalIcon from "../../Icons/PaymentIcons/paypalIcon";
import VenmoIcon from "../../Icons/PaymentIcons/venmoIcon";
import BitcoinIcon from "../../Icons/PaymentIcons/bitcoinIcon";
import EthereumIcon from "../../Icons/PaymentIcons/ethereumIcon";
import InternetIcon from "../../Icons/internetIcon";

import {
  FacebookIcon as FbIcon,
  FacebookShareButton,
  WhatsappIcon as WhpIcon,
  WhatsappShareButton,
  TwitterShareButton,
  TwitterIcon as TwIcon,
  LinkedinShareButton,
  LinkedinIcon as LinkIcon,
  EmailShareButton,
  EmailIcon,
  RedditShareButton,
  RedditIcon,
  LineShareButton,
  LineIcon,
  TelegramIcon as TlgIcon,
  TelegramShareButton,
} from "react-share";

import "./Profile.css";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import PodcastIcon from "../../Icons/MusicIcons/podcastIcon";

function Profile({ match, history }) {
  const [user, setUser] = useState(null);
  const [found, setFound] = useState(false);
  const [loading, setLoading] = useState(true);
  const [openShare, setOpenShare] = useState(false);
  const [openBitcoin, setOpenBitcoin] = useState(null);
  const [openEthereum, setOpenEthereum] = useState(null);

  const [isActive, setIsActive] = useState(false);

  const id = match.params.id;

  const [state, copyToClipboard] = useCopyToClipboard();
  useTitle(user ? `${user.name} | Prestigious` : "Prestigious");

  useEffect(() => {
    getUser();
  }, [id]);

  const getUser = async () => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const { data } = await axios.get(
        `${BASE_URL}/api/users/card/${id}`,
        config
      );
      setUser(data.user);
      console.log("Profile", id, data);
      setFound(data.found);
      setLoading(false);
    } catch (error) {
      console.log("Error", error);
      setFound(false);
      setLoading(false);
    }
  };

  const getIcon = (type) => {
    switch (type) {
      case "PHONE":
        return <PhoneIcon color="white" size="big" />;
      case "WHATS":
        return <WhatsappIcon color="white" size="big" />;
      case "EMAIL":
        return <MailIcon color="white" size="big" />;
      case "SMS":
        return <MessageIcon color="white" size="big" />;
      case "TLG":
        return <TelegramIcon color="white" size="big" />;
      case "LCT":
        return <LocationIcon color="white" size="big" />;
      default:
        return <TelegramIcon color="white" size="big" />;
    }
  };

  const getSocialIcon = (type) => {
    switch (type) {
      case "FACEBOOK":
        return <FacebookIcon color="white" size="big" />;
      case "TWITTER":
        return <TwitterIcon color="white" size="big" />;
      case "INSTAGRAM":
        return <InstaIcon color="white" size="big" />;
      case "TWITCH":
        return <TwitcIcon color="white" size="big" />;
      case "YOUTUBE":
        return <YoutubeIcon color="white" size="big" />;
      case "LNKIN":
        return <LinkedinIcon color="white" size="big" />;
      case "TIKTOK":
        return <TikTokIcon color="white" size="big" />;
      default:
        return <FacebookIcon color="white" size="big" />;
    }
  };

  const getMusicIcon = (type) => {
    switch (type) {
      case "SPOTIFY":
        return <SpotifyIcon color="white" size="big" />;
      case "SOUNDCLOUD":
        return <SoundCloudIcon color="white" size="big" />;
      case "APPLMUSIC":
        return <AppleMusicIcon color="white" size="big" />;
      case "YTMUSIC":
        return <YoutubeMusicIcon color="white" size="big" />;
      case "TIDAL":
        return <TidalIcon color="white" size="big" />;
      case "PODCAST":
        return <PodcastIcon color="white" size="big" />;
      default:
        return <SpotifyIcon color="white" size="big" />;
    }
  };

  const getBussinesIcon = (type) => {
    switch (type) {
      case "CALENDLY":
        return <CalendlyIcon color="white" size="big" />;
      case "YELP":
        return <YelpIcon color="white" size="big" />;
      case "ETSY":
        return <EtsyIcon color="white" size="big" />;
      default:
        return <EtsyIcon color="white" size="big" />;
    }
  };

  const getPaymentIcon = (type) => {
    switch (type) {
      case "CASHAPP":
        return <CashappIcon color="white" size="big" />;
      case "PAYPAL":
        return <PaypalIcon color="white" size="big" />;
      case "VENMO":
        return <VenmoIcon color="white" size="big" />;
      case "BITCOIN":
        return <BitcoinIcon color="white" size="big" />;
      case "ETHEREUM":
        return <EthereumIcon color="white" size="big" />;
      default:
        return <BitcoinIcon color="white" size="big" />;
    }
  };

  const getContactLink = (type, link) => {
    switch (type) {
      case "PHONE":
        return `tel:${link}`;
      case "WHATS":
        return `https://wa.me/${link}`;
      case "EMAIL":
        return `mailto:${link}`;
      case "SMS":
        return `sms:${link}`;
      case "TLG":
        return `https://telegram.me/${link}`;
      case "LCT":
        return link;
      default:
        return `tel:${link}`;
    }
  };

  const handleOpenShare = () => {
    setOpenShare(true);
    /*copyToClipboard(`${BASE_URL}/card/${user.cardId}`)
    toast.success("Copied to Clipboard", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });*/
  };

  const handleLink = async (url, type, category) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const { data } = await axios.post(
        `${BASE_URL}/api/users/clicked/${id}`,
        { type, category },
        config
      );
      console.log(data);
    } catch (error) {
      console.log(error);
    }

    if (type === "BITCOIN") {
      setOpenBitcoin({ url });
    } else if (type === "ETHEREUM") {
      setOpenEthereum({ url });
    } else {
      const link = document.createElement("a");
      link.href = url;
      link.target = "_blank";

      // Append to html link element page
      document.body.appendChild(link);
      // Start download
      link.click();

      // Clean up and remove the link
      link.parentNode.removeChild(link);
    }
  };

  const copyTextToClipboard = (text) => {
    copyToClipboard(text);
    toast.success("Copied to Clipboard", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  if (loading) return <h3>Loading...</h3>;

  if (!found) return <h3>User not found</h3>;

  return (
    <div
      className="profileContainer"
      style={{
        color: user.textColor,
      }}
    >
      <div className="profilePreviewSection">
        <div
          style={{
            backgroundImage: `url(${
              process.env.PUBLIC_URL + "/background.png"
            })`,
          }}
          className="profielPreviewConatiner"
        >
          <div className="profileHeaderImgWrapper">
            {user.headerContent.type == "VIDEO" ? (
              <div className="videocontainer">
                <ReactPlayer
                  playing
                  volume={0}
                  loop={true}
                  width="100%"
                  height="600px"
                  url={user.headerContent.url}
                />
              </div>
            ) : (
              <img
                className="profileHeaderImg"
                src={user.headerContent.url}
                alt=""
              />
            )}
            <div className="profileHeaderImgWrapperShadow"></div>
          </div>
          <div className="profileInfoConatiner">
            <div className="profilePersonalInfo">
              <div className="profileAvatarWrapper">
                <img className="profileAvatar" src={user.avatar} alt="" />
              </div>
              <div className="profilPersonalInfo">
                <h3 className="profileTextInfo">{user.name}</h3>
                <h3 className="profileTextInfo">{user.position}</h3>
                <h3 className="profileTextInfo">{user.location}</h3>
              </div>
            </div>
            <div className="profileContactLinksWrapper">
              <Swiper
                spaceBetween={user.contactLinks.length > 4 ? 5 : 7}
                slidesPerView={
                  user.contactLinks.length > 4
                    ? 5
                    : user.contactLinks.length + 1
                }
                freeMode
                onSlideChange={() => console.log("slide change")}
                onSwiper={(swiper) => console.log(swiper)}
              >
                <SwiperSlide>
                  <a href={`${BASE_URL}/api/users/vcard/${user._id}`}>
                    <div
                      key="2252"
                      style={{ backgroundColor: user.selectedColor }}
                      className="profileContactLinks"
                    >
                      <AddContactIcon color="white" size="big" />
                    </div>
                  </a>
                </SwiperSlide>
                <SwiperSlide>
                  <div
                    onClick={handleOpenShare}
                    key="2289"
                    style={{ backgroundColor: user.selectedColor }}
                    className="profileContactLinks"
                  >
                    <ShareIcon color="white" size="big" />
                  </div>
                </SwiperSlide>
                {user.contactLinks.map((contact) => {
                  const link = getContactLink(contact.type, contact.contact);
                  return (
                    <SwiperSlide>
                      <a href={link}>
                        <div
                          key={contact._id}
                          style={{ backgroundColor: user.selectedColor }}
                          className="profileContactLinks"
                        >
                          {getIcon(contact.type)}
                        </div>
                      </a>
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            </div>
            <div
              className="profileTextAreaContainer"
              style={{ background: user.selectedColor }}
            >
              <textarea
                style={{ width: "100%", color: user.textColor }}
                className="profileTextInfo profileTextarea"
                rows={4}
                type="text"
                readOnly
                placeholder="Bio"
                value={user.bio}
              />
            </div>

            {user.socialLinks.length > 0 && (
              <div
                className="profileLinksCard"
                style={{ background: user.cardsColor }}
              >
                <h2 className="profilecCardTitle">{user.socialLinksLabel}</h2>
                <div className="profileGrid">
                  <Swiper
                    spaceBetween={user.socialLinks.length > 4 ? 3 : 5}
                    slidesPerView={
                      user.socialLinks.length > 4 ? 5 : user.socialLinks.length
                    }
                    freeMode
                    onSlideChange={() => console.log("slide change")}
                    onSwiper={(swiper) => console.log(swiper)}
                  >
                    {user.socialLinks.map((social) => {
                      return (
                        <SwiperSlide className="profileSlide">
                          <span
                            //href={social.link}
                            onClick={() =>
                              handleLink(social.link, social.type, "SOCIAL")
                            }
                          >
                            <div
                              key={social._id}
                              className="profileGridItem"
                              style={{ backgroundColor: user.selectedColor }}
                            >
                              {getSocialIcon(social.type)}
                            </div>
                          </span>
                        </SwiperSlide>
                      );
                    })}
                  </Swiper>
                </div>
              </div>
            )}

            {user.socialLinks.some((e) => e.type === "FACEBOOK") && (
              <div className="accordion-item">
                <div
                  className="accordion-title profileLinksCard"
                  style={{ background: user.cardsColor }}
                  onClick={() => setIsActive(!isActive)}
                >
                  <div>Facebook feed</div>
                  <div>{isActive ? "-" : "+"}</div>
                </div>
                {isActive && (
                  <div className="accordion-content">
                    <div>
                      <iframe
                        title="Prestigious"
                        src={`https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2F${
                          user.socialLinks
                            .find((o) => o.type === "FACEBOOK")
                            .link.split("/")
                            .pop()
                            .split("/")[0]
                        }&tabs=timeline&width=360&height=500&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId`}
                        width="360"
                        height="500"
                        style={{ border: "none", overflow: "hidden" }}
                        scrolling="no"
                        frameborder="0"
                        allowfullscreen="true"
                        allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
                      ></iframe>
                    </div>
                  </div>
                )}
              </div>
            )}

            {user.musicLinks.length > 0 && (
              <div
                className="profileLinksCard"
                style={{ background: user.cardsColor }}
              >
                <h2 className="profilecCardTitle">{user.musicLinksLabel}</h2>
                <div className="profileGrid">
                  <Swiper
                    spaceBetween={user.musicLinks.length > 4 ? 3 : 5}
                    slidesPerView={
                      user.musicLinks.length > 4 ? 5 : user.musicLinks.length
                    }
                    freeMode
                    onSlideChange={() => console.log("slide change")}
                    onSwiper={(swiper) => console.log(swiper)}
                  >
                    {user.musicLinks.map((music) => {
                      return (
                        <SwiperSlide className="profileSlide">
                          <span
                            href={music.contact}
                            onClick={() =>
                              handleLink(music.contact, music.type, "MUSIC")
                            }
                          >
                            <div
                              key={music._id}
                              className="profileGridItem"
                              style={{ backgroundColor: user.selectedColor }}
                            >
                              {getMusicIcon(music.type)}
                            </div>
                          </span>
                        </SwiperSlide>
                      );
                    })}
                  </Swiper>
                </div>
              </div>
            )}

            {user.bussinesLinks.length > 0 && (
              <div
                className="profileLinksCard"
                style={{ background: user.cardsColor }}
              >
                <h2 className="profilecCardTitle">{user.bussinesLinksLabel}</h2>
                <div className="profileGrid">
                  <Swiper
                    spaceBetween={user.bussinesLinks.length > 4 ? 3 : 5}
                    slidesPerView={
                      user.bussinesLinks.length > 4
                        ? 5
                        : user.bussinesLinks.length
                    }
                    freeMode
                    onSlideChange={() => console.log("slide change")}
                    onSwiper={(swiper) => console.log(swiper)}
                  >
                    {user.bussinesLinks.map((bussines) => {
                      return (
                        <SwiperSlide className="profileSlide">
                          <span
                            //href={bussines.contact}
                            onClick={() =>
                              handleLink(
                                bussines.contact,
                                bussines.type,
                                "BUSINESS"
                              )
                            }
                          >
                            <div
                              key={bussines._id}
                              className="profileGridItem"
                              style={{ backgroundColor: user.selectedColor }}
                            >
                              {getBussinesIcon(bussines.type)}
                            </div>
                          </span>
                        </SwiperSlide>
                      );
                    })}
                  </Swiper>
                </div>
              </div>
            )}

            {user.paymentLinks.length > 0 && (
              <div
                className="profileLinksCard"
                style={{ background: user.cardsColor }}
              >
                <h2 className="profilecCardTitle">{user.paymentLinksLabel}</h2>
                <div className="profileGrid">
                  <Swiper
                    spaceBetween={user.paymentLinks.length > 4 ? 3 : 5}
                    slidesPerView={
                      user.paymentLinks.length > 4
                        ? 5
                        : user.paymentLinks.length
                    }
                    freeMode
                    onSlideChange={() => console.log("slide change")}
                    onSwiper={(swiper) => console.log(swiper)}
                  >
                    {user.paymentLinks.map((payment) => {
                      return (
                        <SwiperSlide className="profileSlide">
                          <span
                            //href={payment.contact}
                            onClick={() =>
                              handleLink(
                                payment.contact,
                                payment.type,
                                "PAYMENT"
                              )
                            }
                          >
                            <div
                              key={payment._id}
                              className="profileGridItem"
                              style={{ backgroundColor: user.selectedColor }}
                            >
                              {getPaymentIcon(payment.type)}
                            </div>
                          </span>
                        </SwiperSlide>
                      );
                    })}
                  </Swiper>
                </div>
              </div>
            )}

            {user.customLinks.length > 0 && (
              <div
                className="profileLinksCard"
                style={{ background: user.cardsColor }}
              >
                <h2 className="profilecCardTitle">{user.customLinksLabel}</h2>
                <div className="profileGrid">
                  <Swiper
                    spaceBetween={user.customLinks.length > 4 ? 3 : 5}
                    slidesPerView={
                      user.customLinks.length > 4 ? 5 : user.customLinks.length
                    }
                    freeMode
                    onSlideChange={() => console.log("slide change")}
                    onSwiper={(swiper) => console.log(swiper)}
                  >
                    {user.customLinks.map((custom) => {
                      return (
                        <SwiperSlide className="profileSlide">
                          <a
                            href={custom.link}
                            style={{
                              color: "inherit",
                              textDecoration: "inherit",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <div
                                key={custom._id}
                                className="profileGridItem circleCustomLink"
                                style={{ backgroundColor: user.selectedColor }}
                              >
                                <InternetIcon color="white" size="big" />
                              </div>
                              <span
                                style={{
                                  textAlign: "center",
                                  textDecoration: "none",
                                }}
                              >
                                {custom.text}
                              </span>
                            </div>
                          </a>
                        </SwiperSlide>
                      );
                    })}
                  </Swiper>
                </div>
              </div>
            )}
          </div>
          <BottomSheet
            open={openBitcoin}
            onDismiss={() => setOpenBitcoin(null)}
            defaultSnap={({ maxHeight }) => maxHeight / 2}
            snapPoints={({ minHeight, maxHeight }) => [
              minHeight,
              maxHeight / 2,
            ]}
          >
            {openBitcoin && (
              <div
                style={{
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <QRCode size={200} value={openBitcoin.url} />
                <br />
                <p className="noFontText">{openBitcoin.url}</p>
                <button
                  className="profileViewButton"
                  onClick={() => copyTextToClipboard(openBitcoin.url)}
                >
                  Copy
                </button>
              </div>
            )}
          </BottomSheet>
          <BottomSheet
            open={openEthereum}
            onDismiss={() => setOpenEthereum(null)}
            defaultSnap={({ maxHeight }) => maxHeight / 2}
            snapPoints={({ minHeight, maxHeight }) => [
              minHeight,
              maxHeight / 2,
            ]}
          >
            {openEthereum && (
              <div
                style={{
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <QRCode size={200} value={openEthereum.url} />
                <br />
                <p className="noFontText">{openEthereum.url}</p>
                <button
                  className="profileViewButton"
                  onClick={() => copyTextToClipboard(openEthereum.url)}
                >
                  Copy
                </button>
              </div>
            )}
          </BottomSheet>
          <BottomSheet
            open={openShare}
            onDismiss={() => setOpenShare(false)}
            defaultSnap={({ maxHeight }) => maxHeight / 2}
            snapPoints={({ minHeight, maxHeight }) => [
              minHeight,
              maxHeight / 2,
            ]}
          >
            <WhatsappShareButton
              className="share-btn"
              url={`${BASE_URL}/card/${user.cardId}`}
              title={"Prestigious"}
              separator=":: "
            >
              <WhpIcon size={42} round={true} />
            </WhatsappShareButton>

            <FacebookShareButton
              className="share-btn"
              url={`${BASE_URL}/card/${user.cardId}`}
              quote={`Prestigious ${BASE_URL}/card/${user.cardId}`}
            >
              <FbIcon size={42} round={true} />
            </FacebookShareButton>

            <TwitterShareButton
              className="share-btn"
              url={`${BASE_URL}/card/${user.cardId}`}
              title={"Prestigious"}
            >
              <TwIcon size={42} round={true} />
            </TwitterShareButton>

            <LinkedinShareButton
              className="share-btn"
              url={`${BASE_URL}/card/${user.cardId}`}
              windowWidth={750}
              windowHeight={600}
              title={"Prestigious"}
            >
              <LinkIcon size={42} round={true} />
            </LinkedinShareButton>

            <EmailShareButton
              className="share-btn"
              url={`${BASE_URL}/card/${user.cardId}`}
              subject={"Prestigious"}
              body="Prestigious"
            >
              <EmailIcon size={42} round={true} />
            </EmailShareButton>

            <TelegramShareButton
              className="share-btn"
              url={`${BASE_URL}/card/${user.cardId}`}
              title={"Prestigious"}
            >
              <TlgIcon size={42} round={true} />
            </TelegramShareButton>

            <RedditShareButton
              className="share-btn"
              url={`${BASE_URL}/card/${user.cardId}`}
              title={"Prestigious"}
            >
              <RedditIcon size={42} round={true} />
            </RedditShareButton>

            <LineShareButton
              className="share-btn"
              url={`${BASE_URL}/card/${user.cardId}`}
              title={"Prestigious"}
            >
              <LineIcon size={42} round={true} />
            </LineShareButton>
          </BottomSheet>
        </div>
      </div>
    </div>
  );
}

export default Profile;
