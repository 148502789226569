import { useEffect, useState, useRef } from "react";
import axios from "axios";
import { SketchPicker } from "react-color";
import ReactPlayer from "react-player";
import { BottomSheet } from "react-spring-bottom-sheet";
import { useDebouncedCallback } from "use-debounce";
import { toast } from "react-toastify";
import { Swiper, SwiperSlide } from "swiper/react";
import { usePrestigiousContext } from "../../Context/appContext";
import { BASE_URL } from "../../Constants/Constants";
import UploadIcon from "../../Icons/uploadIcon";
import MailIcon from "../../Icons/mailIcon";
import TelegramIcon from "../../Icons/telegramIcon";
import MessageIcon from "../../Icons/messageIcon";
import PhoneIcon from "../../Icons/phoneIcon";
import LocationIcon from "../../Icons/locationIcon";
import ShareIcon from "../../Icons/shareIcon";
import WhatsappIcon from "../../Icons/whatsappIcon";
import FacebookIcon from "../../Icons/SocialIcons/facebookIcon";
import TwitterIcon from "../../Icons/SocialIcons/twitterIcon";
import TwitcIcon from "../../Icons/SocialIcons/twitchIcon";
import InstaIcon from "../../Icons/SocialIcons/instaIcon";
import YoutubeIcon from "../../Icons/SocialIcons/youtubeIcon";
import LinkedinIcon from "../../Icons/SocialIcons/linkedinIcon";
import TikTokIcon from "../../Icons/SocialIcons/tiktokIcon";
import SpotifyIcon from "../../Icons/MusicIcons/spotifyIcon";
import SoundCloudIcon from "../../Icons/MusicIcons/soundcloudIcon";
import AppleMusicIcon from "../../Icons/MusicIcons/applemusicIcon";
import YoutubeMusicIcon from "../../Icons/MusicIcons/youtubemusicIcon";
import TidalIcon from "../../Icons/MusicIcons/tidalIcon";
import PodcastIcon from "../../Icons/MusicIcons/podcastIcon";
import CalendlyIcon from "../../Icons/calendlyIcon";
import YelpIcon from "../../Icons/yelpIcon";
import EtsyIcon from "../../Icons/etsyIcon";
import CashappIcon from "../../Icons/PaymentIcons/cashappIcon";
import PaypalIcon from "../../Icons/PaymentIcons/paypalIcon";
import VenmoIcon from "../../Icons/PaymentIcons/venmoIcon";
import BitcoinIcon from "../../Icons/PaymentIcons/bitcoinIcon";
import EthereumIcon from "../../Icons/PaymentIcons/ethereumIcon";
import ContactLinks from "../../Screens/Dashboard/Pages/ContactLinks/ContactLinks";
import SocialLinks from "../../Screens/Dashboard/Pages/SocialLinks/SocialLinks";
import CustomLinks from "../../Screens/Dashboard/Pages/CustomLinks/CustomLinks";
import MusicLinks from "../../Screens/Dashboard/Pages/MusicLinks/MusicLinks";
import BussinesLinks from "../../Screens/Dashboard/Pages/BussinesLinks/BussinesLinks";
import PaymentLinks from "../../Screens/Dashboard/Pages/PaymentLinks/PaymentLinks";
import InternetIcon from "../../Icons/internetIcon";
 
import "./Dashboard.css";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";

function Dashboard({ match, history }) {
  const [user, setUser] = useState(null);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [position, setPosition] = useState("");
  const [bio, setBio] = useState("");
  const [location, setLocation] = useState("");
  const [phone, setPhone] = useState("");
  const [headerContent, setHeaderContent] = useState("");
  const [avatar, setAvatar] = useState("");
  const [contactLinks, setContactLinks] = useState([]);
  const [socialLinksLabel, setSocialLinksLabel] = useState("");
  const [socialLinks, setSocialLinks] = useState([]);
  const [customLinksLabel, setCustomLinksLabel] = useState("");
  const [customLinks, setCustomLinks] = useState([]);
  const [musicLinksLabel, setMusicLinksLabel] = useState("");
  const [musicLinks, setMusicLinks] = useState([]);
  const [bussinesLinksLabel, setBussinesLinksLabel] = useState("");
  const [bussinesLinks, setBussinesLinks] = useState([]);
  const [paymentLinksLabel, setPaymentLinksLabel] = useState("");
  const [paymentLinks, setPaymentLinks] = useState([]);
  const [selectedColor, setSelectedColor] = useState("#ac8454");
  const [textColor, setTextColor] = useState("#f9f9f9");
  const [cardsColor, setCardsColor] = useState("#ac8454");
  const [showPicker, setShowPicker] = useState(false);
  const [showPickerText, setShowPickerText] = useState(false);
  const [showPickerCard, setShowPickerCard] = useState(false);
  const fileInputRef = useRef();
  const avatarInputRef = useRef();
  const toastId = useRef(null);

  const [headerBS, setOpenHBS] = useState(false);
  const [contactLink, setContactLink] = useState(null);
  const [socialLink, setSocialLink] = useState(null);
  const [customLink, setCustomLink] = useState(null);
  const [musicLink, setMusicLink] = useState(null);
  const [bussinesLink, setBussinesLink] = useState(null);
  const [paymentLink, setPaymentLink] = useState(null);

  const { currentUser } = usePrestigiousContext();

  useEffect(() => {
    getMe();
  }, []);

  const getMe = async () => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${currentUser.token}`,
        },
      };

      const { data } = await axios.get(`${BASE_URL}/api/users/data/me`, config);
      setName(data.name);
      setEmail(data.email);
      setPosition(data.position);
      setBio(data.bio);
      setLocation(data.location);
      setPhone(data.phone);
      setHeaderContent(data.headerContent);
      setAvatar(data.avatar);
      setContactLinks(data.contactLinks);
      setSocialLinksLabel(data.socialLinksLabel);
      setSocialLinks(data.socialLinks);
      setCustomLinksLabel(data.customLinksLabel);
      setCustomLinks(data.customLinks);
      setMusicLinksLabel(data.musicLinksLabel);
      setMusicLinks(data.musicLinks);
      setBussinesLinksLabel(data.bussinesLinksLabel);
      setBussinesLinks(data.bussinesLinks);
      setPaymentLinksLabel(data.paymentLinksLabel);
      setPaymentLinks(data.paymentLinks);
      setSelectedColor(data.selectedColor);
      setTextColor(data.textColor);
      setCardsColor(data.cardsColor);
      setUser(data);
      console.log("Me", data);
    } catch (error) {
      console.log("Error", error);
    }
  };

  const update = async () => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${currentUser.token}`,
        },
      };

      const { data } = await axios.put(
        `${BASE_URL}/api/users/data/me`,
        {
          name,
          email,
          position,
          bio,
          location,
          phone,
          headerContent,
          avatar,
          selectedColor,
          textColor,
          cardsColor,
          socialLinksLabel,
          musicLinksLabel,
          bussinesLinksLabel,
          customLinksLabel,
          paymentLinksLabel,
        },
        config
      );
      setUser(data);
      //console.log(data);
      toast.success("Updated", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } catch (error) {
       console.log("Error", getError(error));
      toast.error("Error", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const getError = (error) => {
    let e = error;
    if (error.response) {
      e = error.response.data;                   // data, status, headers
      if (error.response.data && error.response.data.error) {
        e = error.response.data.error;           // my app specific keys override
      }
    } else if (error.message) {
      e = error.message;
    } else {
      e = "Unknown error occured";
    }
    return e;
  }

  const debounced = useDebouncedCallback(
    // function
    (value) => {
      update();
    },
    // delay in ms
    2500
  );

  const uploadHeaderImg = async (e) => {
    const file = e.target.files[0];
    const fileSize = file.size / 1024 / 1024;
    if (fileSize <= 15) {
      const formData = new FormData();
      formData.append("image", file);
      try {
        const config = {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        };

        const { data } = await axios.post(
          `${BASE_URL}/api/upload`,
          formData,
          config
        );
        setHeaderContent({ type: "IMAGE", url: `${BASE_URL}${data}` });
        setOpenHBS(false);
        debounced();
      } catch (error) {
        console.error(error);
      }
    } else {
      toast.error("File too big", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const uploadAvatarImg = async (e) => {
    const file = e.target.files[0];
    const fileSize = file.size / 1024 / 1024;
    if (fileSize <= 12) {
      const formData = new FormData();
      formData.append("image", file);

      try {
        const config = {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        };

        const { data } = await axios.post(
          `${BASE_URL}/api/upload`,
          formData,
          config
        );
        setAvatar(`${BASE_URL}${data}`);
        debounced();
      } catch (error) {
        console.error(error);
      }
    } else {
      toast.error("File too big", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const handleChangeComplete = (color) => {
    setSelectedColor(color.hex);
    debounced();
  };

  const handleChangeCompleteText = (color) => {
    setTextColor(color.hex);
    debounced();
  };

  const handleChangeCompleteCard = (color) => {
    setCardsColor(color.hex);
    debounced();
  };

  const onAddContactLink = () => {
    setContactLink({ contact: "", type: "", isEdit: false });
  };

  const onEditContactLink = (contact) => {
    setContactLink({ ...contact, isEdit: true });
  };

  const onAddSocialLink = () => {
    setSocialLink({ name: "", link: "", type: "", isEdit: false });
  };

  const onEditSocialLink = (social) => {
    setSocialLink({ ...social, isEdit: true });
  };

  const onAddCustomLink = () => {
    setCustomLink({ text: "", link: "", isEdit: false });
  };

  const onEditCustomLink = (custom) => {
    setCustomLink({ ...custom, isEdit: true });
  };

  const onAddMusicLink = () => {
    setMusicLink({ contact: "", type: "", isEdit: false });
  };

  const onEditMusicLink = (contact) => {
    setMusicLink({ ...contact, isEdit: true });
  };

  const onAddBussinesLink = () => {
    setBussinesLink({ contact: "", type: "", isEdit: false });
  };

  const onEditBussinesLink = (contact) => {
    setBussinesLink({ ...contact, isEdit: true });
  };

  const onAddPaymentLink = () => {
    setPaymentLink({ contact: "", type: "", isEdit: false });
  };

  const onEditPaymentLink = (contact) => {
    setPaymentLink({ ...contact, isEdit: true });
  };

  const getIcon = (type) => {
    switch (type) {
      case "PHONE":
        return <PhoneIcon color="white" size="big" />;
      case "WHATS":
        return <WhatsappIcon color="white" size="big" />;
      case "EMAIL":
        return <MailIcon color="white" size="big" />;
      case "SMS":
        return <MessageIcon color="white" size="big" />;
      case "TLG":
        return <TelegramIcon color="white" size="big" />;
      case "LCT":
        return <LocationIcon color="white" size="big" />;
      case "SHR":
        return <ShareIcon color="white" size="big" />;
      default:
        return <TelegramIcon color="white" size="big" />;
    }
  };

  const getSocialIcon = (type) => {
    switch (type) {
      case "FACEBOOK":
        return <FacebookIcon color="white" size="big" />;
      case "TWITTER":
        return <TwitterIcon color="white" size="big" />;
      case "INSTAGRAM":
        return <InstaIcon color="white" size="big" />;
      case "TWITCH":
        return <TwitcIcon color="white" size="big" />;
      case "YOUTUBE":
        return <YoutubeIcon color="white" size="big" />;
      case "LNKIN":
        return <LinkedinIcon color="white" size="big" />;
      case "TIKTOK":
        return <TikTokIcon color="white" size="big" />;
      default:
        return <FacebookIcon color="white" size="big" />;
    }
  };

  const getMusicIcon = (type) => {
    switch (type) {
      case "SPOTIFY":
        return <SpotifyIcon color="white" size="big" />;
      case "SOUNDCLOUD":
        return <SoundCloudIcon color="white" size="big" />;
      case "APPLMUSIC":
        return <AppleMusicIcon color="white" size="big" />;
      case "YTMUSIC":
        return <YoutubeMusicIcon color="white" size="big" />;
      case "TIDAL":
        return <TidalIcon color="white" size="big" />;
      case "PODCAST":
        return <PodcastIcon color="white" size="big" />;
      default:
        return <SpotifyIcon color="white" size="big" />;
    }
  };

  const getBussinesIcon = (type) => {
    switch (type) {
      case "CALENDLY":
        return <CalendlyIcon color="white" size="big" />;
      case "YELP":
        return <YelpIcon color="white" size="big" />;
      case "ETSY":
        return <EtsyIcon color="white" size="big" />;
      default:
        return <EtsyIcon color="white" size="big" />;
    }
  };

  const getPaymentIcon = (type) => {
    switch (type) {
      case "CASHAPP":
        return <CashappIcon color="white" size="big" />;
      case "PAYPAL":
        return <PaypalIcon color="white" size="big" />;
      case "VENMO":
        return <VenmoIcon color="white" size="big" />;
      case "BITCOIN":
        return <BitcoinIcon color="white" size="big" />;
      case "ETHEREUM":
        return <EthereumIcon color="white" size="big" />;    
      default:
        return <BitcoinIcon color="white" size="big" />;
    }
  };
 
  if (!user) return <h3>Loading...</h3>;

  return (
    <div className="container">
      <div className="previewSection">
        <div
          style={{
            backgroundImage: `url(${
              process.env.PUBLIC_URL + "/background.png"
            })`,
            backgroundPosition: "23% 75%",
          }}
          className="previewConatiner"
        >
          <div className="headerImgWrapper">
            {user.headerContent.type == "VIDEO" ? (
              <div className="videocontainer">
                <ReactPlayer
                  playing
                  volume={0}
                  loop={true}
                  width="100%"
                  height="600px"
                  url={headerContent.url}
                />
              </div>
            ) : (
              <img className="headerImg" src={headerContent.url} alt="" />
            )}
            <span className="uploadHeaderBtn" onClick={() => setOpenHBS(true)}>
              <UploadIcon size="big" />
              <span style={{ textAlign: "right" }}>BG</span>
            </span>

            <div className="closeBtnWrapper" onClick={() => history.goBack()}>
              <span>X</span>
            </div>
            <div className="colorBtnWrapper">
              <div
                style={{
                  padding: "5px",
                  background: "#fff",
                  borderRadius: "8px",
                  boxShadow: "0 0 0 1px rgba(0,0,0,.1)",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent:"center",
                  alignItems:"center",
                  cursor: "pointer",
                }}
                onClick={() => setShowPicker(!showPicker)}
              >
                <div
                  style={{
                    width: "40px",
                    height: "12px",
                    borderRadius: "2px",
                    background: selectedColor,
                  }}
                />
                <span>Color</span>
              </div>
              {showPicker ? (
                <div
                  style={{
                    position: "absolute",
                    zIndex: "2",
                  }}
                >
                  <div
                    style={{
                      position: "fixed",
                      top: "0px",
                      right: "0px",
                      bottom: "0px",
                      left: "0px",
                    }}
                    onClick={() => setShowPicker(false)}
                  />
                  <SketchPicker
                    color={selectedColor}
                    disableAlpha
                    onChangeComplete={handleChangeComplete}
                  />
                </div>
              ) : null}
            </div>

            <div className="textcolorBtnWrapper">
              <div
                style={{
                  padding: "5px",
                  background: "#fff",
                  borderRadius: "8px",
                  boxShadow: "0 0 0 1px rgba(0,0,0,.1)",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent:"center",
                  alignItems:"center",
                  cursor: "pointer",
                }}
                onClick={() => setShowPickerText(!showPickerText)}
              >
                <div
                  style={{
                    width: "40px",
                    height: "12px",
                    borderRadius: "2px",
                    background: textColor,
                  }}
                />
                <span>Text</span>
              </div>
              {showPickerText ? (
                <div
                  style={{
                    position: "absolute",
                    zIndex: "2",
                  }}
                >
                  <div
                    style={{
                      position: "fixed",
                      top: "0px",
                      right: "0px",
                      bottom: "0px",
                      left: "0px",
                    }}
                    onClick={() => setShowPickerText(false)}
                  />
                  <SketchPicker
                    color={textColor}
                    disableAlpha
                    onChangeComplete={handleChangeCompleteText}
                  />
                </div>
              ) : null}
            </div>

            <div className="cardscolorBtnWrapper">
              <div
                style={{
                  padding: "5px",
                  background: "#fff",
                  borderRadius: "8px",
                  boxShadow: "0 0 0 1px rgba(0,0,0,.1)",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent:"center",
                  alignItems:"center",
                  cursor: "pointer",
                }}
                onClick={() => setShowPickerCard(!showPickerCard)}
              >
                <div
                  style={{
                    width: "40px",
                    height: "12px",
                    borderRadius: "2px",
                    background: cardsColor,
                  }}
                />
                <span>Cards</span>
              </div>
              {showPickerCard ? (
                <div
                  style={{
                    position: "absolute",
                    zIndex: "1",
                  }}
                >
                  <div
                    style={{
                      position: "fixed",
                      top: "0px",
                      right: "0px",
                      bottom: "0px",
                      left: "0px",
                    }}
                    onClick={() => setShowPickerCard(false)}
                  />
                  <SketchPicker
                    color={cardsColor}
                    disableAlpha
                    onChangeComplete={handleChangeCompleteCard}
                  />
                </div>
              ) : null}
            </div>
            <div className="headerImgWrapperShadow"></div>
          </div>
          <div className="infoConatiner">
            <div className="personalInfo">
              <div className="avatarWrapper">
                <img className="avatar" src={avatar} alt="" />
                <span
                  className="uploadAvatarBtn"
                  onClick={() => avatarInputRef.current.click()}
                >
                  <UploadIcon />
                </span>
                <input
                  onChange={uploadAvatarImg}
                  multiple={false}
                  ref={avatarInputRef}
                  type="file"
                  hidden
                />
              </div>
              <div className="personalInfo">
                <input
                  className="inputControlEditable"
                  type="text"
                  placeholder="Name"
                  value={name}
                  style={{
                    color: textColor,
                  }}
                  onChange={async (e) => {
                    await setName(e.target.value);
                    debounced();
                  }}
                />
                <input
                  className="inputControlEditable subInfo"
                  type="text"
                  placeholder="Position"
                  value={position}
                  style={{
                    color: textColor,
                  }}
                  onChange={async (e) => {
                    await setPosition(e.target.value);
                    debounced();
                  }}
                />
                <input
                  className="inputControlEditable subInfo"
                  type="text"
                  placeholder="Location"
                  value={location}
                  style={{
                    color: textColor,
                  }}
                  onChange={async (e) => {
                    await setLocation(e.target.value);
                    debounced();
                  }}
                />
              </div>
            </div>
            <div className="contactLinksWrapper">
              <Swiper
                spaceBetween={contactLinks.length > 4 ? 5 : 7}
                slidesPerView={
                  contactLinks.length > 4 ? 5 : contactLinks.length + 1
                }
                freeMode
                onSlideChange={() => console.log("slide change")}
                onSwiper={(swiper) => console.log(swiper)}
              >
                {contactLinks.map((contact) => {
                  return (
                    <SwiperSlide>
                      <div
                        key={contact._id}
                        onClick={() => onEditContactLink(contact)}
                        style={{ backgroundColor: selectedColor }}
                        className="contactLinks"
                      >
                        {getIcon(contact.type)}
                      </div>
                    </SwiperSlide>
                  );
                })}
                <SwiperSlide>
                  <div
                    className="contactLinks addInfoLinks"
                    onClick={() => onAddContactLink()}
                  >
                    <span>+</span>
                  </div>
                </SwiperSlide>
              </Swiper>
            </div>
            <div
              className="textAreaContainer"
              style={{ background: selectedColor }}
            >
              <textarea
                style={{ width: "100%", color: textColor }}
                className="inputControlEditable textarea"
                rows={4}
                type="text"
                placeholder="Bio"
                value={bio}
                onChange={async (e) => {
                  await setBio(e.target.value);
                  debounced();
                }}
              />
            </div>
            <div
              className="linksCard"
              style={{ backgroundColor: cardsColor }}
            >
              <input
                className="cardTitle inputControlEditable"
                type="text"
                //placeholder="Name"
                style={{
                  color: textColor
                }}
                value={socialLinksLabel}
                onChange={async (e) => {
                  await setSocialLinksLabel(e.target.value);
                  debounced();
                }}
              />
              {/*<h2 className="cardTitle">Social Links</h2>*/}
              <div className="grid">
                <Swiper
                  className="swipperContainer"
                  spaceBetween={socialLinks.length > 4 ? 3 : 5}
                  slidesPerView={
                    socialLinks.length > 4 ? 5 : socialLinks.length + 1
                  }
                  freeMode
                  onSlideChange={() => console.log("slide change")}
                  onSwiper={(swiper) => console.log(swiper)}
                >
                  {socialLinks.map((social) => {
                    return (
                      <SwiperSlide
                      className="gridSlide"
                      >
                        <div
                          key={social._id}
                          onClick={() => onEditSocialLink(social)}
                          className="gridItem"
                          style={{ backgroundColor: selectedColor }}
                        >
                          {getSocialIcon(social.type)}
                        </div>
                      </SwiperSlide>
                    );
                  })}
                  <SwiperSlide
                  className="gridSlide"
                  >
                    <div
                      className="gridItem addGridItem"
                      onClick={() => onAddSocialLink()}
                    >
                      <p>Add +</p>
                    </div>
                  </SwiperSlide>
                </Swiper>
              </div>
            </div>
            <div
              className="linksCard"
              style={{ backgroundColor: cardsColor }}
            >
              <input
                className="cardTitle inputControlEditable"
                type="text"
                //placeholder="Name"
                style={{
                  color: textColor
                }}
                value={musicLinksLabel}
                onChange={async (e) => {
                  await setMusicLinksLabel(e.target.value);
                  debounced();
                }}
              />
              {/*<h2 className="cardTitle">Music Links</h2>*/}
              <div className="grid">
                <Swiper
                className="swipperContainer"
                  spaceBetween={musicLinks.length > 4 ? 3 : 5}
                  slidesPerView={
                    musicLinks.length > 4 ? 5 : musicLinks.length + 1
                  }
                  freeMode
                  onSlideChange={() => console.log("slide change")}
                  onSwiper={(swiper) => console.log(swiper)}
                >
                  {musicLinks.map((music) => {
                    return (
                      <SwiperSlide
                      className="gridSlide"
                      >
                        <div
                          key={music._id}
                          onClick={() => onEditMusicLink(music)}
                          className="gridItem"
                          style={{ backgroundColor: selectedColor }}
                        >
                          {getMusicIcon(music.type)}
                        </div>
                      </SwiperSlide>
                    );
                  })}
                  <SwiperSlide
                  className="gridSlide"
                  >
                    <div
                      className="gridItem addGridItem"
                      onClick={() => onAddMusicLink()}
                    >
                      <p>Add +</p>
                    </div>
                  </SwiperSlide>
                </Swiper>
              </div>
            </div>
            <div
              className="linksCard"
              style={{ backgroundColor: cardsColor }}
            >
              <input
                className="cardTitle inputControlEditable"
                type="text"
                //placeholder="Name"
                style={{
                  color: textColor
                }}
                value={bussinesLinksLabel}
                onChange={async (e) => {
                  await setBussinesLinksLabel(e.target.value);
                  debounced();
                }}
              />
              {/*<h2 className="cardTitle">Bussiness Links</h2>*/}
              <div className="grid">
                <Swiper
                className="swipperContainer"
                  spaceBetween={bussinesLinks.length > 4 ? 3 : 5}
                  slidesPerView={
                    bussinesLinks.length > 4 ? 5 : bussinesLinks.length + 1
                  }
                  freeMode
                  onSlideChange={() => console.log("slide change")}
                  onSwiper={(swiper) => console.log(swiper)}
                >
                  {bussinesLinks.map((bussines) => {
                    return (
                      <SwiperSlide
                      className="gridSlide"
                      >
                        <div
                          key={bussines._id}
                          onClick={() => onEditBussinesLink(bussines)}
                          className="gridItem"
                          style={{ backgroundColor: selectedColor }}
                        >
                          {getBussinesIcon(bussines.type)}
                        </div>
                      </SwiperSlide>
                    );
                  })}
                  <SwiperSlide
                  className="gridSlide"
                  >
                    <div
                      className="gridItem addGridItem"
                      onClick={() => onAddBussinesLink()}
                    >
                      <p>Add +</p>
                    </div>
                  </SwiperSlide>
                </Swiper>
              </div>
            </div>
            <div
              className="linksCard"
              style={{ backgroundColor: cardsColor }}
            >
              <input
                className="cardTitle inputControlEditable"
                type="text"
                style={{
                  color: textColor
                }}
                //placeholder="Name"
                value={paymentLinksLabel}
                onChange={async (e) => {
                  await setPaymentLinksLabel(e.target.value);
                  debounced();
                }}
              />
              {/*<h2 className="cardTitle">Payment Links</h2>*/}
              <div className="grid">
                <Swiper
                className="swipperContainer"
                  spaceBetween={paymentLinks.length > 4 ? 3 : 5}
                  slidesPerView={
                    paymentLinks.length > 4 ? 5 : paymentLinks.length + 1
                  }
                  freeMode
                  onSlideChange={() => console.log("slide change")}
                  onSwiper={(swiper) => console.log(swiper)}
                >
                  {paymentLinks.map((payment) => {
                    return (
                      <SwiperSlide
                      className="gridSlide"
                      >
                        <div
                          key={payment._id}
                          onClick={() => onEditPaymentLink(payment)}
                          className="gridItem"
                          style={{ backgroundColor: selectedColor }}
                        >
                          {getPaymentIcon(payment.type)}
                        </div>
                      </SwiperSlide>
                    );
                  })}
                  <SwiperSlide
                  className="gridSlide"
                  >
                    <div
                      className="gridItem addGridItem"
                      onClick={() => onAddPaymentLink()}
                    >
                      <p>Add +</p>
                    </div>
                  </SwiperSlide>
                </Swiper>
              </div>
            </div>
            <div
              className="linksCard"
              style={{ backgroundColor: cardsColor }}
            >
              <input
                className="cardTitle inputControlEditable"
                type="text"
                style={{
                  color: textColor
                }}
                //placeholder="Name"
                value={customLinksLabel}
                onChange={async (e) => {
                  await setCustomLinksLabel(e.target.value);
                  debounced();
                }}
              />
              {/*<h2 className="cardTitle">Custom Links</h2>*/}
              <div className="grid">
                <Swiper
                className="swipperContainer"
                  spaceBetween={customLinks.length > 4 ? 3 : 5}
                  slidesPerView={
                    customLinks.length > 4 ? 5 : customLinks.length + 1
                  }
                  freeMode
                  onSlideChange={() => console.log("slide change")}
                  onSwiper={(swiper) => console.log(swiper)}
                >
                  {customLinks.map((custom) => {
                    return (
                      <SwiperSlide
                      className="gridSlide"
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <div
                            key={custom._id}
                            onClick={() => onEditCustomLink(custom)}
                            className="gridItem circleCustomLink"
                            style={{ backgroundColor: selectedColor }}
                          >
                            <InternetIcon color="white" size="big" />
                          </div>
                          <span style={{ textAlign: "center", color: textColor }}>
                            {custom.text}
                          </span>
                        </div>
                      </SwiperSlide>
                    );
                  })}
                  <SwiperSlide
                  className="gridSlide"
                  >
                    <div
                      className="gridItem addGridItem"
                      onClick={() => onAddCustomLink()}
                    >
                      <p>Add +</p>
                    </div>
                  </SwiperSlide>
                </Swiper>
              </div>
            </div>
          </div>
        </div>
      </div>

      <BottomSheet
        open={headerBS}
        onDismiss={() => setOpenHBS(false)}
        defaultSnap={({ maxHeight }) => maxHeight / 2}
        snapPoints={({ minHeight, maxHeight }) => [minHeight, maxHeight / 2]}
      >
        <div
          style={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <input
            className="inputControl"
            placeholder="Video Url"
            //value={headerContent.url}
            onChange={(e) =>
              setHeaderContent({ type: "VIDEO", url: e.target.value })
            }
          />
          <button
            className="saveButton"
            onClick={() => fileInputRef.current.click()}
          >
            Pick an Image
          </button>
          <button
            className="saveButton"
            onClick={() => {
              setOpenHBS(false);
              update();
            }}
          >
            Save
          </button>
        </div>
        <input
          onChange={uploadHeaderImg}
          multiple={false}
          ref={fileInputRef}
          type="file"
          hidden
        />
      </BottomSheet>

      <BottomSheet
        open={contactLink}
        onDismiss={() => setContactLink(null)}
        defaultSnap={({ maxHeight }) => maxHeight / 2}
        snapPoints={({ minHeight, maxHeight }) => [minHeight, maxHeight / 2]}
      >
        {contactLink && (
          <ContactLinks
            contactLink={contactLink}
            allContactLinks={contactLinks}
            setContactLinks={setContactLinks}
            close={() => setContactLink(null)}
          />
        )}
      </BottomSheet>
      <BottomSheet
        open={socialLink}
        onDismiss={() => setSocialLink(null)}
        defaultSnap={({ maxHeight }) => maxHeight / 1}
        snapPoints={({ minHeight, maxHeight }) => [minHeight, maxHeight / 2]}
      >
        {socialLink && (
          <SocialLinks
            socialLink={socialLink}
            allSocialLinks={socialLinks}
            setSocialLinks={setSocialLinks}
            close={() => setSocialLink(null)}
          />
        )}
      </BottomSheet>
      <BottomSheet
        open={customLink}
        onDismiss={() => setCustomLink(null)}
        defaultSnap={({ maxHeight }) => maxHeight / 2}
        snapPoints={({ minHeight, maxHeight }) => [minHeight, maxHeight / 2]}
      >
        {customLink && (
          <CustomLinks
            customLink={customLink}
            allCustomLinks={customLinks}
            setCustomLinks={setCustomLinks}
            close={() => setCustomLink(null)}
          />
        )}
      </BottomSheet>
      <BottomSheet
        open={musicLink}
        onDismiss={() => setMusicLink(null)}
        defaultSnap={({ maxHeight }) => maxHeight / 2}
        snapPoints={({ minHeight, maxHeight }) => [minHeight, maxHeight / 2]}
      >
        {musicLink && (
          <MusicLinks
            musicLink={musicLink}
            allMusicLinks={musicLinks}
            setMusicLinks={setMusicLinks}
            close={() => setMusicLink(null)}
          />
        )}
      </BottomSheet>
      <BottomSheet
        open={bussinesLink}
        onDismiss={() => setBussinesLink(null)}
        defaultSnap={({ maxHeight }) => maxHeight / 2}
        snapPoints={({ minHeight, maxHeight }) => [minHeight, maxHeight / 2]}
      >
        {bussinesLink && (
          <BussinesLinks
            bussinesLink={bussinesLink}
            allBussinesLinks={bussinesLinks}
            setBussinesLinks={setBussinesLinks}
            close={() => setBussinesLink(null)}
          />
        )}
      </BottomSheet>
      <BottomSheet
        open={paymentLink}
        onDismiss={() => setPaymentLink(null)}
        defaultSnap={({ maxHeight }) => maxHeight / 2}
        snapPoints={({ minHeight, maxHeight }) => [minHeight, maxHeight / 2]}
      >
        {paymentLink && (
          <PaymentLinks
            paymentLink={paymentLink}
            allPaymentLinks={paymentLinks}
            setPaymentLinks={setPaymentLinks}
            close={() => setPaymentLink(null)}
          />
        )}
      </BottomSheet>
    </div>
  );
}

export default Dashboard;
