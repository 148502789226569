import React from "react";

export default function AddContactIcon({ size, color = "#4691f6" }) {
  return (
    <div>
      <span className="icon-container">
        <svg 
        width={size === "big" ? "1.5em" : "1.2em"}
        height={size === "big" ? "1.5em" : "1.2em"}
        fill={color}
        viewBox="0 0 16 16">
          <path
            fill={color}
            d="M6 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0zm4 8c0 1-1 1-1 1H1s-1 0-1-1 1-4 6-4 6 3 6 4zm-1-.004c-.001-.246-.154-.986-.832-1.664C9.516 10.68 8.289 10 6 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10z"
          ></path>

          <path
            fill={color}
            fillRule="evenodd"
            d="M13.5 5a.5.5 0 0 1 .5.5V7h1.5a.5.5 0 0 1 0 1H14v1.5a.5.5 0 0 1-1 0V8h-1.5a.5.5 0 0 1 0-1H13V5.5a.5.5 0 0 1 .5-.5z"
          ></path>
        </svg>
      </span>
    </div>
  );
}
