import { useEffect, useRef, useState } from "react";
import StripeCheckout from "react-stripe-checkout";
import axios from "axios";
import { toast } from "react-toastify";
import { BASE_URL } from "../../Constants/Constants";
import { usePrestigiousContext } from "../../Context/appContext";
import "./NewOrder.css";
 
const KEY = process.env.REACT_APP_STRIPE;

function NewOrder({ match, history }) {
  const price = 15;
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zipcode, setZipCode] = useState("");
  const [printText, setPrintText] = useState("");
  const [address, setAddress] = useState("");
  const [deliveryInstr, setDeliveryInstr] = useState("");
  const [logo, setLogo] = useState("");
  const [stripeToken, setStripeToken] = useState(null);
  const [successPay, setSuccesPay] = useState(false);

  const logoInputRef = useRef();

  const { createOrder } = usePrestigiousContext();

  useEffect(() => {
    const makeRequest = async () => {
      try {
        const res = await axios.post(`${BASE_URL}/api/checkout/payment`, {
          tokenId: stripeToken.id,
          amount: price * 100,
        });
        /* history.push("/success", {
          stripeData: res.data,
        });*/
        onCreateOrder(res.data);
      } catch (error) {
        console.log("Error", error);
        const errorlog =
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message;
        console.log(errorlog);
        toast.error("Error " + errorlog, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    };
    stripeToken && makeRequest();
  }, [stripeToken, history]);

  const onToken = (token) => {
    setStripeToken(token);
  };

  const onCreateOrder = async (payment) => {
    const order = {
      user_name: name,
      user_email: email,
      user_phone: phone,
      delivery_city: city,
      delivery_state: state,
      delivery_zipcode: zipcode,
      delivery_address: address,
      delivery_instructions: deliveryInstr,
      print_text: printText,
      logo,
      payment_id: payment.id,
    };
    const data = await createOrder(order);
    console.log(data);
    setSuccesPay(true);
  };

  const uploadImgLogo = async (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("image", file);

    try {
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };

      const { data } = await axios.post(
        `${BASE_URL}/api/upload`,
        formData,
        config
      );
      setLogo(`${BASE_URL}${data}`);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="orderContainer">
      {!successPay ? (
        <div className="orderFormWrapper">
          <input
            className="orderInputControl"
            value={name}
            placeholder="Name"
            required={true}
            onChange={(e) => setName(e.target.value)}
          />
          <input
            className="orderInputControl"
            value={email}
            placeholder="Email"
            required={true}
            onChange={(e) => setEmail(e.target.value)}
          />
          <input
            className="orderInputControl"
            value={phone}
            placeholder="Phone"
            required={true}
            onChange={(e) => setPhone(e.target.value)}
          />
          <input
            className="orderInputControl"
            value={address}
            placeholder="Address"
            required={true}
            onChange={(e) => setAddress(e.target.value)}
          />
          <input
            className="orderInputControl"
            value={city}
            placeholder="City"
            required={true}
            onChange={(e) => setCity(e.target.value)}
          />
          <input
            className="orderInputControl"
            value={state}
            placeholder="State"
            required={true}
            onChange={(e) => setState(e.target.value)}
          />
          <input
            className="orderInputControl"
            value={zipcode}
            placeholder="Zip Code"
            required={true}
            onChange={(e) => setZipCode(e.target.value)}
          />
          <input
            className="orderInputControl"
            value={deliveryInstr}
            placeholder="Delivery Instructions"
            required={true}
            onChange={(e) => setDeliveryInstr(e.target.value)}
          />
          <input
            className="orderInputControl"
            value={printText}
            placeholder="Print Text"
            required={true}
            onChange={(e) => setPrintText(e.target.value)}
          />
          {logo && <img alt="logo" src={logo} />}
          <input
            onChange={uploadImgLogo}
            multiple={false}
            ref={logoInputRef}
            type="file"
            hidden
          />
          <button
            onClick={() => logoInputRef.current.click()}
            className="orderBtn"
          >
            Attach Logo
          </button>
          {name && address && email && phone && city && zipcode && (
            <StripeCheckout
              name="Prestigious Shop"
              image="https://getprestigious.com/prestigiouslogo.png"
              billingAddress={false}
              shippingAddress={false}
              description={`Your total is ${price}`}
              amount={price * 100}
              token={onToken}
              stripeKey={KEY}
            >
              <button className="orderBtn">Order</button>
            </StripeCheckout>
          )}
        </div>
      ) : (

        <h1 style={{color: "#fff"}} >Payed Successfully</h1>
      )}
    </div>
  );
} 
 
export default NewOrder;
