import React, { useEffect, useRef, useState } from "react";
import { usePrestigiousContext } from "../../Context/appContext";
import PhoneIcon from "../../Icons/phoneIcon";
import MailIcon from "../../Icons/mailIcon";
import LocationIcon from "../../Icons/locationIcon";
import "./WhatsPrestigious.css";

function WhatsPrestigious({ history }) {
  const { logout, currentUser } = usePrestigiousContext();
  const vidRef = useRef(null);
  const [videoState, setVideoState] = useState(false);
  const [windowDimension, setWindowDimension] = useState(null);
  const [showMibileMenu, setShowMobileMenu] = useState(false);

  useEffect(() => {
    setWindowDimension(window.innerWidth);
  }, []);

  useEffect(() => {
    function handleResize() {
      setWindowDimension(window.innerWidth);
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const isMobile = windowDimension <= 640;

  const handlePlayVideo = () => {
    if (!videoState) {
      vidRef.current.play();
    } else {
      vidRef.current.pause();
    }
  };
 
  return (
    <div className="contactContainer">
      {isMobile ? (
        <div className="mobileMenuContainer">
          <div className="contactLogoWrapper">
            <div>
              <img
                alt="logo"
                className="contactMenuLogo"
                src={`${process.env.PUBLIC_URL + "/prestigiouslogo.png"}`}
              />
            </div>
            <h2 className="homeTitle">Prestigious</h2>
          </div>
          <span onClick={() => setShowMobileMenu(true)}>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24">
              <path d="M4 6h16v2H4zm0 5h16v2H4zm0 5h16v2H4z"></path>
            </svg>
          </span>
          {showMibileMenu ? (
            <div className="homeMenuMobile">
              <span
                className="homeMenuMobileClose"
                onClick={() => setShowMobileMenu(false)}
              >
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24">
                  <path d="m16.192 6.344-4.243 4.242-4.242-4.242-1.414 1.414L10.535 12l-4.242 4.242 1.414 1.414 4.242-4.242 4.243 4.242 1.414-1.414L13.364 12l4.242-4.242z"></path>
                </svg>
              </span>
              <div className="homeMobileLinks">
                <a href="/" className="topMenuLink">
                  <h3>Home</h3>
                </a>
                <a href="about" className="topMenuLink">
                  <h3> What's Prestigious?</h3>
                </a>
                <a href="/gallery" className="topMenuLink">
                  <h3>Coming Soon</h3>
                </a>
                <a href="/contact" className="topMenuLink">
                  <h3> Contact</h3>
                </a>
                {currentUser ? (
                  <>
                    {currentUser.isAdmin ? (
                      <a href="/admin" className="topMenuLink">
                        <h3>Admin</h3>
                      </a>
                    ) : (
                      <a href="/dashboard" className="topMenuLink">
                        <h3>Editor</h3>
                      </a>
                    )}
                    <span onClick={logout} className="topMenuLink textWhite">
                      <h3>Log out</h3>
                    </span>
                  </>
                ) : (
                  <>
                    <a href="/login" className="topMenuLink">
                      <h3>Login</h3>
                    </a>
                    <a href="/signup" className="topMenuLink">
                      <h3>Signup</h3>
                    </a>
                  </>
                )}
              </div>
            </div>
          ) : null}
        </div>
      ) : (
        <div className="contactTopMenu">
          <div className="contactLogoWrapper">
            <div>
              <img
                alt="logo"
                className="contactMenuLogo"
                src={`${process.env.PUBLIC_URL + "/prestigiouslogo.png"}`}
              />
            </div>
            <h2 className="homeTitle">Prestigious</h2>
          </div>
          <div className="contactMenuLinks">
            <a href="/" className="contactTopMenuLink">
              <h3>Home</h3>
            </a>
            <a href="/about" className="topMenuLink">
              <h3> What's Prestigious?</h3>
            </a>
            <a href="/gallery" className="topMenuLink">
              <h3>Coming Soon</h3>
            </a>
            <a href="/contact" className="topMenuLink">
              <h3> Contact</h3>
            </a>
          </div>
          <div>
            {currentUser ? (
              <div>
                {currentUser.isAdmin ? (
                  <button
                    onClick={() => history.push("/admin")}
                    className="homeButton"
                  >
                    Admin
                  </button>
                ) : (
                  <button
                    onClick={() => history.push("/dashboard")}
                    className="homeButton"
                  >
                    Editor
                  </button>
                )}
                <button onClick={logout} className="homeButton">
                  Log out
                </button>
              </div>
            ) : (
              <div>
                <button
                  onClick={() => history.push("/login")}
                  className="homeButton"
                >
                  Log In
                </button>
                <button
                  onClick={() => history.push("/signup")}
                  className="homeButton"
                >
                  Sign up
                </button>
              </div>
            )}
          </div>
        </div>
      )}
      <div
        style={{
          backgroundImage: `url(${process.env.PUBLIC_URL + "/contact.png"})`,
        }}
        className="contactHero"
      >
        <div className="contactHeroContent">
          <span>We can help you</span>
          <h2>About Us</h2>
        </div>
      </div>

      <br />
      <br />
      <br />
      <br />

      <section className="homeSection pb-155">
        <div className="videoContainer ">
          <div className="videoContent">
            <video
              ref={vidRef}
              className="video"
              muted={true}
              onPlay={() => setVideoState(true)}
              onPause={() => setVideoState(false)}
              onEnded={() => setVideoState(false)}
              id="video-file"
            >
              <source
                src={`${process.env.PUBLIC_URL + "/video.mp4"}`}
                type="video/mp4"
              />
            </video>

            <button
              className="videoButton"
              id="video-button"
              onClick={handlePlayVideo}
            >
              {!videoState ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                >
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path d="M16.394 12L10 7.737v8.526L16.394 12zm2.982.416L8.777 19.482A.5.5 0 0 1 8 19.066V4.934a.5.5 0 0 1 .777-.416l10.599 7.066a.5.5 0 0 1 0 .832z" />
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                >
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path d="M6 5h2v14H6V5zm10 0h2v14h-2V5z" />
                </svg>
              )}
            </button>
          </div>
        </div>
      </section>

      <div className="aboutTitleWrapper">
        <span>Since 2021</span>
        <h2>Presence, prestige, and perfection</h2>
        <div className="aboutCardInfo">
          <div>
            <img src={process.env.PUBLIC_URL + "/banner.jpg"} alt="" className="aboutCardImg" />
          </div>
          <div class="abouTextWrapper">
            <span>Prestigious</span>
            <h3>Digital is a fact, so why not take advantage of it? </h3>
            <p>
              beprestigious.com is a new type of business card. It's more than a
              digital card, it's a bridge between your physical and digital
              world. Share a digital you with an elegant presence and refined
              charm with just one swipe of your finger.{" "}
            </p>
          </div>
        </div>
      </div>

      <section className="homeSection pb-155">
        <div className="text-center mb-50">
          <div className="sectionTitleIcon">
            <img
              className="smallIcon"
              src={`${process.env.PUBLIC_URL + "/prestigiouslogo.png"}`}
              alt="logo"
            />
          </div>
          <span className="titleTag">PEOPLE</span>
          <h2 className="sectionTitle">Everyone is using the prestigious card</h2>
        </div>

        <div className="homeFeatureContainer homeGrid clumn3Grid">
          <div className="featureCard">
 
            <h3 className="featureCardText textWhite">NFC and QR technology</h3>
            <span class="featureCardCount">01</span>
          </div>
          <div className="featureCard">
            <h3 className="featureCardText textWhite">
              Profile creation on Prestigious
            </h3>
            <span class="featureCardCount">02</span>
          </div>
          <div className="featureCard">
 
            <h3 className="featureCardText textWhite">
              Add contact information and websites
            </h3>
            <span class="featureCardCount">03</span>
          </div>
        </div>
      </section>

      <footer className="homeSection pb-155 footerContainer">
        <div className="footerVision">
          <img
            className="smallIcon"
            src={`${process.env.PUBLIC_URL + "/prestigiouslogo.png"}`}
            alt="logo"
          />
          <span>
            Presence, prestige, and perfection are what you'll find with our
            most exclusive product - premium, innovative, and elegant. There's
            only one prestigious.
          </span>
        </div>

        <div className="footerMenu">
          <h2 className="textWhite">Site Map</h2>
          <div className="footerMenuLinks">
            <div className="footerMenuLinksColumn">
              <div className="footerLink">
                <a href="/">Home</a>
              </div>
              <div className="footerLink">
                <a href="/about">What's Prestigious?</a>
              </div>
            </div>
            <div className="footerMenuLinksColumn">
              <div className="footerLink">
                <a href="/gallery">Coming Soon</a>
              </div>
              <div className="footerLink">
                <a href="/contact">Contact</a>
              </div>
            </div>
          </div>
        </div>

        <div className="footerContactContainer">
          <h2 className="textWhite">Contact Us</h2>
          <div className="footerContactCard">
            <div className="featuredCardIcon">
              <PhoneIcon size="big" color="#ad8457" />
            </div>
            <div className="footerInfoWrapper">
              <h3 className=" textWhite middleTextSize text-center">
                Phone Number
              </h3>
              <br />
              <span>+714 410 9141</span>
            </div>
          </div>
          <div className="footerContactCard">
            <div className="featuredCardIcon">
              <MailIcon size="big" color="#ad8457" />
            </div>
            <div className="footerInfoWrapper">
              <h3 className=" textWhite middleTextSize text-center">
                Email Address
              </h3>
              <br />
              <span>info@prestigeous.com</span>
            </div>
          </div>
          <div className="footerContactCard">
            <div className="featuredCardIcon">
              <LocationIcon size="big" color="#ad8457" />
            </div>
            <div className="footerInfoWrapper">
              <h3 className=" textWhite middleTextSize text-center">
                Office Address
              </h3>
              <br />
              <span>1671 w katella ave ste 255, Anaheim CA 92802</span>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default WhatsPrestigious;
