import { useEffect, useState } from "react";
import axios from "axios";
import { usePrestigiousContext } from "../../../../Context/appContext";
import { BASE_URL } from "../../../../Constants/Constants";

function ContactLinks({ match, history, contactLink, allContactLinks, setContactLinks, close }) {
  const [contact, setContact] = useState(contactLink.contact);
  const [type, setType] = useState(contactLink.type);

  const { currentUser } = usePrestigiousContext();

  useEffect(() => {}, []);

  const update = async () => {
    if (contact && type) {
      let contactLinks = allContactLinks;
      if (contactLink.isEdit) {
        const index = allContactLinks.findIndex(
          (o) => o._id === contactLink._id
        );
        contactLinks[index].contact = contact;
        contactLinks[index].type = type;
      } else {
        contactLinks = [
          ...allContactLinks,
          {
            contact,
            type,
          },
        ];
      }

      console.log(contactLinks);
      try {
        const config = {
          headers: {
            Authorization: `Bearer ${currentUser.token}`,
          },
        };

        const { data } = await axios.put(
          `${BASE_URL}/api/users/data/me`,
          {
            contactLinks,
          },
          config
        );
        setContactLinks(data.contactLinks);
        close();
      } catch (error) {
        console.log("Error", error);
        //handleErrors(error);
      }
    } else {
      alert("Fill all fields");
    }
  };

  const deleteContact = async () => {
    const contactLinks = allContactLinks.filter(
      (o) => o._id !== contactLink._id
    );
    console.log(contactLinks);
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${currentUser.token}`,
        },
      };

      const { data } = await axios.put(
        `${BASE_URL}/api/users/data/me`,
        {
          contactLinks,
        },
        config
      );
      setContactLinks(data.contactLinks);
      close();
    } catch (error) {
      console.log("Error", error);
      //handleErrors(error);
    }
  };

  const getPlaceholder = (type) => {
    switch (type) {
      case "PHONE":
        return "Phone number";
      case "WHATS":
        return "Whatsapp";
      case "EMAIL":
        return "Email";
      case "SMS":
        return "Phone number";
      case "TLG":
        return "Telegram url";
      case "LCT":
        return "Map url";
      default:
        return "";
    }
  };

  return (
    <div
      style={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <select
        className="inputControl"
        value={type}
        onChange={(e) => setType(e.target.value)}
      >
        <option value="">Pick</option>
        <option value="PHONE">Phone</option>
        <option value="EMAIL">Email</option>
        <option value="WHATS">Whatsapp</option>
        <option value="SMS">SMS</option>
        <option value="TLG">Telegram</option>
        <option value="LCT">Location</option>
      </select>
      <input
        className="inputControl"
        value={contact}
        placeholder={getPlaceholder(type)}
        onChange={(e) => setContact(e.target.value)}
      />
      <button className="saveButton" onClick={update}>
        {contactLink.isEdit ? "Edit" : "Add"}
      </button>
      {contactLink.isEdit && (
        <button className="saveButton" onClick={deleteContact}>
          Delete
        </button>
      )}
    </div>
  );
}

export default ContactLinks;
