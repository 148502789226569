import axios from "axios";
import { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import moment from "moment";
import { useTitle } from "react-use";
import { toast } from "react-toastify";
import { BASE_URL } from "../../Constants/Constants";
import { usePrestigiousContext } from "../../Context/appContext";
import "./Admin.css";

function Messages({ match, history }) {
  const [messages, setMessages] = useState(null);
  const [errors, setErrors] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");



  const keyword = match.params.keyword;
  const pageNumber = match.params.pageNumber || 1;

  useTitle("Prestigious | Messages");

  const { currentUser, activeUser } = usePrestigiousContext();

  useEffect(() => {
    getUsers(keyword, pageNumber);
  }, [keyword, pageNumber]);

  const getUsers = async (keyword = "", pageNumber = "") => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${currentUser.token}`,
        },
      };
      const { data } = await axios.get(
        `${BASE_URL}/api/contact?keyword=${keyword}&pageNumber=${pageNumber}`,
        config
      );
      console.log(data);
      //setInputs(data.users.)
      setMessages(data);
    } catch (error) {
      console.log(error);
      setErrors(error);
    }
  };

  const submitHandler = (e) => {
    e.preventDefault();
    if (searchTerm.trim()) {
      history.push(`/admin/messages/search/${searchTerm}`);
    } else {
      history.push("/admin/messages");
    }
  };

  const handleChange = (event) => {
    const value = event.selected + 1;
    if (keyword) {
      history.push(`/admin/messages/search/${keyword}/page/${value}`);
    } else {
      history.push(`/admin/messages/page/${value}`);
    }
  };




  if (errors) return <div>Error {errors}</div>;

  if (!messages) return <div>Cargando...</div>;

  return (
    <div
      className="adminContainer"
      style={{
        backgroundImage: `url(${process.env.PUBLIC_URL + "/background.png"})`,
      }}
    >
      <div className="adminheaderContainer">
        <h2 className="pageTitle">Users</h2>
        <div className="adminSearchWrapper">
          <input
            className="inputControl"
            placeholder="Search"
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <button className="adminBtn" onClick={submitHandler}>
            Search
          </button>
        </div>
      </div>
      <div className="adminTableContainer">
        <table className="adminTable">
          <thead className="adminTableHead">
            <tr className="adminTableRow">
            <th className="adminTableCellHead adminTableCell ">Name</th>
              <th className="adminTableCellHead adminTableCell ">Email</th>
              <th className="adminTableCellHead adminTableCell">Phone</th>
              <th className="adminTableCellHead adminTableCell">Subject</th>
              <th className="adminTableCellHead adminTableCell">Message</th>
            </tr>
          </thead>
          <tbody className="adminTableBody">
            {messages?.contacts.map((message, index) => {
              return (
                <tr className="adminTableRow" key={message._id}>
                  <td className="adminTableCell adminTableCellBody">
                  {message.name}
                  </td>
                  <td className="adminTableCell adminTableCellBody">
                    {message.email}
                  </td>
                  <td className="adminTableCell adminTableCellBody">
                    {message.phone}
                  </td>
                  <td className="adminTableCell adminTableCellBody">
                    {message.subject}
                  </td>
                  <td className="adminTableCell adminTableCellBody">
                    {message.message}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <ReactPaginate
        previousLabel="Previous"
        nextLabel="Next"
        pageClassName="adminPageWrapper"
        pageLinkClassName="adminPageItem"
        previousClassName="adminPageWrapper"
        previousLinkClassName="adminPageItem"
        nextClassName="adminPageWrapper"
        nextLinkClassName="adminPageItem"
        breakLabel="..."
        breakClassName="adminPageWrapper"
        breakLinkClassName="adminPageItem"
        pageCount={messages.pages}
        marginPagesDisplayed={2}
        pageRangeDisplayed={5}
        onPageChange={handleChange}
        containerClassName="adminPagination"
        activeClassName="adminActiveItem"
        activeLinkClassName="adminActiveItem"
        //forcePage={pageNumber}
      />
    </div>
  );
}

export default Messages;
