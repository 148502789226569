import React, { useEffect, useRef, useState } from "react";
import { usePrestigiousContext } from "../../Context/appContext";
import { useTitle } from "react-use";
import YoutubeIcon from "../../Icons/SocialIcons/youtubeIcon";
import InternetIcon from "../../Icons/internetIcon";
import ImageIcon from "../../Icons/imageIcon";
import CardIcon from "../../Icons/cardIcon";
import EditIcon from "../../Icons/editIcon";
import PersonsIcon from "../../Icons/personsIcon";
import AddContactIcon from "../../Icons/addContactIcon";
import MessageIcon from "../../Icons/messageIcon";
import PhoneIcon from "../../Icons/phoneIcon";
import MailIcon from "../../Icons/mailIcon";
import LocationIcon from "../../Icons/locationIcon";
import "./Home.css";

function Home({ history }) {
  const vidRef = useRef(null);
  const [videoState, setVideoState] = useState(false);
  const [showMibileMenu, setShowMobileMenu] = useState(false);
  const { logout, currentUser } = usePrestigiousContext();
  useTitle("Prestigious");
  const [windowDimension, setWindowDimension] = useState(null);

  useEffect(() => {
    setWindowDimension(window.innerWidth);
  }, []);

  useEffect(() => {
    function handleResize() {
      setWindowDimension(window.innerWidth);
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const isMobile = windowDimension <= 640;

  const handlePlayVideo = () => {
    if (!videoState) {
      vidRef.current.play();
    } else {
      vidRef.current.pause();
    }
  };

  return (
    <div
      className="homeContainer"
      style={
        {
          // backgroundImage: `url(${process.env.PUBLIC_URL + "/background.png"})`,
        }
      }
    >
      <div className="homeContent">
        <img
          src={process.env.PUBLIC_URL + "/banner.jpg"}
          alt=""
          className="home__img"
        />
        <div className="bannerOverlay home__img"></div>

        <div className="homeNavbar">
          <div>
            <h2 className="homeTitle">Prestigious</h2>
          </div>
          <div>
            {isMobile ? (
              <div>
                <span onClick={() => setShowMobileMenu(true)}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                  >
                    <path d="M4 6h16v2H4zm0 5h16v2H4zm0 5h16v2H4z"></path>
                  </svg>
                </span>
                {showMibileMenu ? (
                  <div className="homeMenuMobile">
                    <span
                      className="homeMenuMobileClose"
                      onClick={() => setShowMobileMenu(false)}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                      >
                        <path d="m16.192 6.344-4.243 4.242-4.242-4.242-1.414 1.414L10.535 12l-4.242 4.242 1.414 1.414 4.242-4.242 4.243 4.242 1.414-1.414L13.364 12l4.242-4.242z"></path>
                      </svg>
                    </span>
                    <div className="homeMobileLinks">
                      <a href="/" className="topMenuLink">
                        <h3>Home</h3>
                      </a>
                      <a href="about" className="topMenuLink">
                        <h3> What's Prestigious?</h3>
                      </a>
                      <a href="/gallery" className="topMenuLink">
                        <h3>Coming Soon</h3>
                      </a>
                      <a href="/contact" className="topMenuLink">
                        <h3> Contact</h3>
                      </a>
                      {currentUser ? (
                        <>
                          {currentUser.isAdmin ? (
                            <a href="/admin" className="topMenuLink">
                              <h3>Admin</h3>
                            </a>
                          ) : (
                            <a href="/dashboard" className="topMenuLink">
                              <h3>Editor</h3>
                            </a>
                          )}
                          <span onClick={logout} className="topMenuLink textWhite">
                          <h3>Log out</h3>
                          </span>
                        </>
                      ) : (
                        <>
                          <a href="/login" className="topMenuLink">
                            <h3>Login</h3>
                          </a>
                          <a href="/signup" className="topMenuLink">
                            <h3>Signup</h3>
                          </a>
                        </>
                      )}
                    </div>
                  </div>
                ) : null}
              </div>
            ) : (
              <div>
                {currentUser ? (
                  <div>
                    {currentUser.isAdmin ? (
                      <button
                        onClick={() => history.push("/admin")}
                        className="homeButton"
                      >
                        Admin
                      </button>
                    ) : (
                      <button
                        onClick={() => history.push("/dashboard")}
                        className="homeButton"
                      >
                        Editor
                      </button>
                    )}
                    <button onClick={logout} className="homeButton">
                      Log out
                    </button>
                  </div>
                ) : (
                  <div>
                    <button
                      onClick={() => history.push("/login")}
                      className="homeButton"
                    >
                      Log In
                    </button>
                    <button
                      onClick={() => history.push("/signup")}
                      className="homeButton"
                    >
                      Sign up
                    </button>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>

        <div className="topMenuContainer">
          <a href="/" className="topMenuLink">
            <h3>Home</h3>
          </a>
          <a href="about" className="topMenuLink">
            <h3> What's Prestigious?</h3>
          </a>
          <div>
            <img
              alt="logo"
              className="homeLogo"
              src={`${process.env.PUBLIC_URL + "/prestigiouslogo.png"}`}
            />
          </div>
          <a href="/gallery" className="topMenuLink">
            <h3>Coming Soon</h3>
          </a>
          <a href="/contact" className="topMenuLink">
            <h3> Contact</h3>
          </a>
        </div>
        <div className="heroContainer">
          <div>
            <h2 className="homeCaptionText">Prestigious card </h2>
          </div>
          <h2 className="mainText">The new way of presenting yourself</h2>

          <div className="heroButtonsWrapper">
            <a href="/login" className="homeHeroButton homeFilledButton">
              Register now
            </a>
            <a href="/buy" className="homeHeroButton homeBorderButton">
              Buy now
            </a>
          </div>
        </div>
      </div>

      <section className="pb-155 pt-115">
        <div className="homeSectionFluid">
          <div className="text-center mb-50">
            <div className="sectionTitleIcon">
              <img
                className="smallIcon"
                src={`${process.env.PUBLIC_URL + "/prestigiouslogo.png"}`}
                alt="logo"
              />
            </div>
            <span className="titleTag">Powerful Contact Integration Tool</span>
            <h2 className="sectionTitle">Profits</h2>
          </div>

          <div className="homeFeatureContainer homeGrid clumn4Grid">
            <div className="featureCardWrapper">
              <div
                className="cardItem"
                style={{
                  backgroundImage: `url(${
                    process.env.PUBLIC_URL + "/prestigiouslogo.png"
                  })`,
                }}
              >
                <div className="cardTitleWrapper">
                  <h3 class="homeCardTitle">Prestigious</h3>
                  <div class="displayCardTextHover">
                    <p class="text-white">
                      The best option on the market, building customer loyalty
                      and meeting objectives
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="featureCardWrapper">
              <div
                className="cardItem"
                style={{
                  backgroundImage: `url(${
                    process.env.PUBLIC_URL + "/prestigiouslogo.png"
                  })`,
                }}
              >
                <div className="cardTitleWrapper">
                  <h3 class="homeCardTitle">Sales</h3>
                  <div class="displayCardTextHover">
                    <p class="text-white">
                      Free Registration, Retail / Wholesale
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="featureCardWrapper">
              <div
                className="cardItem"
                style={{
                  backgroundImage: `url(${
                    process.env.PUBLIC_URL + "/prestigiouslogo.png"
                  })`,
                }}
              >
                <div className="cardTitleWrapper">
                  <h3 class="homeCardTitle">Fair price</h3>
                  <div class="displayCardTextHover">
                    <p class="text-white">We provide various functionalities</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="featureCardWrapper">
              <div
                className="cardItem"
                style={{
                  backgroundImage: `url(${
                    process.env.PUBLIC_URL + "/prestigiouslogo.png"
                  })`,
                }}
              >
                <div className="cardTitleWrapper">
                  <h3 class="homeCardTitle">Shipments</h3>
                  <div class="displayCardTextHover">
                    <p class="text-white">
                      We ship to the entire United States
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <br />
          <br />
          <div class="subtitleSection textWhite text-center mb-80">
            <h4>
              ¡Order yours now! Press the button to be able to send us your
              data.
              <br />
              We will manufacture your card immediately.
              <br />
              <br />
              <a href="/buy" class="homeHeroButton homeFilledButton">
                Buy Now
              </a>
            </h4>
          </div>
        </div>
      </section>

      <section className="homeSection pb-155">
        <div className="videoContainer ">
          <div className="videoContent">
            <video
              ref={vidRef}
              className="video"
              muted={true}
              onPlay={() => setVideoState(true)}
              onPause={() => setVideoState(false)}
              onEnded={() => setVideoState(false)}
              id="video-file"
            >
              <source
                src={`${process.env.PUBLIC_URL + "/video.mp4"}`}
                type="video/mp4"
              />
            </video>

            <button
              className="videoButton"
              id="video-button"
              onClick={handlePlayVideo}
            >
              {!videoState ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                >
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path d="M16.394 12L10 7.737v8.526L16.394 12zm2.982.416L8.777 19.482A.5.5 0 0 1 8 19.066V4.934a.5.5 0 0 1 .777-.416l10.599 7.066a.5.5 0 0 1 0 .832z" />
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                >
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path d="M6 5h2v14H6V5zm10 0h2v14h-2V5z" />
                </svg>
              )}
            </button>
          </div>
        </div>
      </section>

      <section className="homeSection pb-155">
        <div className="text-center mb-50">
          <div className="sectionTitleIcon">
            <img
              className="smallIcon"
              src={`${process.env.PUBLIC_URL + "/prestigiouslogo.png"}`}
              alt="logo"
            />
          </div>
          <span className="titleTag">PRESTIGIOUS CARD</span>
          <h2 className="sectionTitle">Our Best Feature</h2>
        </div>

        <div className="homeFeatureContainer homeGrid clumn3Grid">
          <div className="featureCard">
            <div className="featuredCardIcon">
              <CardIcon size="big" color="#ad8457" />
            </div>
            <h3 className="featureCardText textWhite">NFC and QR technology</h3>
            <span class="featureCardCount">01</span>
          </div>
          <div className="featureCard">
            <div className="featuredCardIcon">
              <EditIcon size="big" color="#ad8457" />
            </div>
            <h3 className="featureCardText textWhite">
              Profile creation on Prestigious
            </h3>
            <span class="featureCardCount">02</span>
          </div>
          <div className="featureCard">
            <div className="featuredCardIcon">
              <AddContactIcon size="big" color="#ad8457" />
            </div>
            <h3 className="featureCardText textWhite">
              Add contact information and websites
            </h3>
            <span class="featureCardCount">03</span>
          </div>
          <div className="featureCard">
            <div className="featuredCardIcon">
              <ImageIcon size="big" color="#ad8457" />
            </div>
            <h3 className="featureCardText textWhite">
              Add logo, photo and cover to your profile
            </h3>
            <span class="featureCardCount">04</span>
          </div>
          <div className="featureCard">
            <div className="featuredCardIcon">
              <YoutubeIcon size="big" color="#ad8457" />
            </div>
            <h3 className="featureCardText textWhite">
              Add illustrative videos
            </h3>
            <span class="featureCardCount">05</span>
          </div>
          <div className="featureCard">
            <div className="featuredCardIcon">
              <InternetIcon size="big" color="#ad8457" />
            </div>
            <h3 className="featureCardText textWhite">
              Integration with social networks
            </h3>
            <span class="featureCardCount">06</span>
          </div>
        </div>
      </section>
      <section className="homeSection pb-155">
        <div className="counterContainer">
          <div className="counterWrapper">
            <h2 className="sectionTitle">84K</h2>
            <span className="titleTag">Card Users</span>
          </div>
          <div className="counterWrapper">
            <h2 className="sectionTitle">10M</h2>
            <span className="titleTag">Happy Feedbacks</span>
          </div>
          <div className="counterWrapper">
            <h2 className="sectionTitle">2k</h2>
            <span className="titleTag">Bussines</span>
          </div>
          <div className="counterWrapper">
            <h2 className="sectionTitle">100M</h2>
            <span className="titleTag">Happy Clients</span>
          </div>
        </div>
      </section>

      <section className="homeSection">
        <span className="titleTag">
          INCREASE YOUR RELATIONSHIPS WITH MORE PROSPECTS
        </span>
        <h2 className="sectionTitle experinceTitle">Experiences</h2>
        <div className="galleryGrid">
          <figure className="gallery__item gallery__item--1 galleryImgContainer">
            <img
              src={process.env.PUBLIC_URL + "/eventos.png"}
              alt="alt text"
              className="galleryImg"
            />
            <div className="experinceInfoTextContainer textWhite">
              <h2>Events</h2>
            </div>
          </figure>
          <figure className="gallery__item gallery__item--2 galleryImgContainer">
            <img
              src={process.env.PUBLIC_URL + "/fiesta.png"}
              alt="alt text"
              className="galleryImg"
            />
            <div className="experinceInfoTextContainer textWhite">
              <h2>Party</h2>
            </div>
          </figure>
          <figure className="gallery__item gallery__item--3 galleryImgContainer">
            <img
              src={process.env.PUBLIC_URL + "/conference.png"}
              alt="alt text"
              className="galleryImg"
            />
            <div className="experinceInfoTextContainer textWhite">
              <h2>Conference</h2>
            </div>
          </figure>
          <figure className="gallery__item gallery__item--4 galleryImgContainer">
            <img
              src={process.env.PUBLIC_URL + "/negocios.png"}
              alt="alt text"
              className="galleryImg"
            />
            <div className="experinceInfoTextContainer textWhite">
              <h2>Business</h2>
            </div>
          </figure>
        </div>
      </section>

      <section className="homeSection pb-155">
        <div className="text-center mb-50">
          <div className="sectionTitleIcon">
            <img
              className="smallIcon"
              src={`${process.env.PUBLIC_URL + "/prestigiouslogo.png"}`}
              alt="logo"
            />
          </div>
          <span className="titleTag">PRESTIGIOUS MODEL</span>
          <h2 className="textWhite">
            Serving entrepreneurs, professionals and businessmen in the business
            world
          </h2>
        </div>
        <div className="homeFeatureContainer homeGrid clumn3Grid middleTextSizes">
          <div className="featureCard informativeCards">
            <div className="featuredCardIcon">
              <EditIcon size="big" color="#ad8457" />
            </div>
            <h3 className="featureCardText textWhite middleTextSize text-center">
              Fill out our form
            </h3>
          </div>
          <div className="featureCard informativeCards">
            <div className="featuredCardIcon">
              <MessageIcon size="big" color="#ad8457" />
            </div>
            <h3 className="featureCardText textWhite middleTextSize text-center">
              Show other people and share your information
            </h3>
          </div>
          <div className="featureCard informativeCards">
            <div className="featuredCardIcon">
              <PersonsIcon size="big" color="#ad8457" />
            </div>
            <h3 className="featureCardText textWhite middleTextSize text-center">
              Receive new prospects interested in what you do
            </h3>
          </div>
        </div>
      </section>

      <footer className="homeSection pb-155 footerContainer">
        <div className="footerVision">
          <img
            className="smallIcon"
            src={`${process.env.PUBLIC_URL + "/prestigiouslogo.png"}`}
            alt="logo"
          />
          <span>
            Presence, prestige, and perfection are what you'll find with our
            most exclusive product - premium, innovative, and elegant. There's
            only one prestigious.
          </span>
        </div>

        <div className="footerMenu">
          <h2 className="textWhite">Site Map</h2>
          <div className="footerMenuLinks">
            <div className="footerMenuLinksColumn">
              <div className="footerLink">
                <a href="/">Home</a>
              </div>
              <div className="footerLink">
                <a href="/about">What's Prestigious?</a>
              </div>
            </div>
            <div className="footerMenuLinksColumn">
              <div className="footerLink">
                <a href="/gallery">Coming Soon</a>
              </div>
              <div className="footerLink">
                <a href="/contact">Contact</a>
              </div>
            </div>
          </div>
        </div>

        <div className="footerContactContainer">
          <h2 className="textWhite">Contact Us</h2>
          <div className="footerContactCard">
            <div className="featuredCardIcon">
              <PhoneIcon size="big" color="#ad8457" />
            </div>
            <div className="footerInfoWrapper">
              <h3 className=" textWhite middleTextSize text-center">
                Phone Number
              </h3>
              <br />
              <span>+714 410 9141</span>
            </div>
          </div>
          <div className="footerContactCard">
            <div className="featuredCardIcon">
              <MailIcon size="big" color="#ad8457" />
            </div>
            <div className="footerInfoWrapper">
              <h3 className=" textWhite middleTextSize text-center">
                Email Address
              </h3>
              <br />
              <span>info@prestigeous.com</span>
            </div>
          </div>
          <div className="footerContactCard">
            <div className="featuredCardIcon">
              <LocationIcon size="big" color="#ad8457" />
            </div>
            <div className="footerInfoWrapper">
              <h3 className=" textWhite middleTextSize text-center">
                Office Address
              </h3>
              <br />
              <span>1671 w katella ave ste 255, Anaheim CA 92802</span>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default Home;
