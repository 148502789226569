import React, { useEffect, useState } from "react";
import { usePrestigiousContext } from "../../Context/appContext";
import PhoneIcon from "../../Icons/phoneIcon";
import MailIcon from "../../Icons/mailIcon";
import LocationIcon from "../../Icons/locationIcon";
import "./ComingSoon.css";

function ComingSoon({ history }) {
  const { logout, currentUser } = usePrestigiousContext();
  const [windowDimension, setWindowDimension] = useState(null);
  const [showMibileMenu, setShowMobileMenu] = useState(false);

  useEffect(() => {
    setWindowDimension(window.innerWidth);
  }, []);

  useEffect(() => {
    function handleResize() {
      setWindowDimension(window.innerWidth);
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const isMobile = windowDimension <= 640;
 
  return (
    <div className="contactContainer">
      {isMobile ? (
        <div className="mobileMenuContainer">
          <div className="contactLogoWrapper">
            <div>
              <img
                alt="logo"
                className="contactMenuLogo"
                src={`${process.env.PUBLIC_URL + "/prestigiouslogo.png"}`}
              />
            </div>
            <h2 className="homeTitle">Prestigious</h2>
          </div>
          <span onClick={() => setShowMobileMenu(true)}>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24">
              <path d="M4 6h16v2H4zm0 5h16v2H4zm0 5h16v2H4z"></path>
            </svg>
          </span>
          {showMibileMenu ? (
            <div className="homeMenuMobile">
              <span
                className="homeMenuMobileClose"
                onClick={() => setShowMobileMenu(false)}
              >
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24">
                  <path d="m16.192 6.344-4.243 4.242-4.242-4.242-1.414 1.414L10.535 12l-4.242 4.242 1.414 1.414 4.242-4.242 4.243 4.242 1.414-1.414L13.364 12l4.242-4.242z"></path>
                </svg>
              </span>
              <div className="homeMobileLinks">
                <a href="/" className="topMenuLink">
                  <h3>Home</h3>
                </a>
                <a href="about" className="topMenuLink">
                  <h3> What's Prestigious?</h3>
                </a>
                <a href="/gallery" className="topMenuLink">
                  <h3>Coming Soon</h3>
                </a>
                <a href="/contact" className="topMenuLink">
                  <h3> Contact</h3>
                </a>
                {currentUser ? (
                  <>
                    {currentUser.isAdmin ? (
                      <a href="/admin" className="topMenuLink">
                        <h3>Admin</h3>
                      </a>
                    ) : (
                      <a href="/dashboard" className="topMenuLink">
                        <h3>Editor</h3>
                      </a>
                    )}
                    <span onClick={logout} className="topMenuLink textWhite">
                      <h3>Log out</h3>
                    </span>
                  </>
                ) : (
                  <>
                    <a href="/login" className="topMenuLink">
                      <h3>Login</h3>
                    </a>
                    <a href="/signup" className="topMenuLink">
                      <h3>Signup</h3>
                    </a>
                  </>
                )}
              </div>
            </div>
          ) : null}
        </div>
      ) : (
        <div className="contactTopMenu">
          <div className="contactLogoWrapper">
            <div>
              <img
                alt="logo"
                className="contactMenuLogo"
                src={`${process.env.PUBLIC_URL + "/prestigiouslogo.png"}`}
              />
            </div>
            <h2 className="homeTitle">Prestigious</h2>
          </div>
          <div className="contactMenuLinks">
            <a href="/" className="contactTopMenuLink">
              <h3>Home</h3>
            </a>
            <a href="/about" className="topMenuLink">
              <h3> What's Prestigious?</h3>
            </a>
            <a href="/gallery" className="topMenuLink">
              <h3>Coming Soon</h3>
            </a>
            <a href="/contact" className="topMenuLink">
              <h3> Contact</h3>
            </a>
          </div>
          <div>
            {currentUser ? (
              <div>
                {currentUser.isAdmin ? (
                  <button
                    onClick={() => history.push("/admin")}
                    className="homeButton"
                  >
                    Admin
                  </button>
                ) : (
                  <button
                    onClick={() => history.push("/dashboard")}
                    className="homeButton"
                  >
                    Editor
                  </button>
                )}
                <button onClick={logout} className="homeButton">
                  Log out
                </button>
              </div>
            ) : (
              <div>
                <button
                  onClick={() => history.push("/login")}
                  className="homeButton"
                >
                  Log In
                </button>
                <button
                  onClick={() => history.push("/signup")}
                  className="homeButton"
                >
                  Sign up
                </button>
              </div>
            )}
          </div>
        </div>
      )}
      <div
        style={{
          backgroundImage: `url(${process.env.PUBLIC_URL + "/contact.png"})`,
        }}
        className="contactHero"
      >
        <div className="contactHeroContent">
          <span>We can help you</span>
          <h2>Coming Soon</h2>
        </div>
      </div>

      <br />
      <br />
      <br />
      <br />

        <div className="previewCardInfo">
          <div>
            <img src={process.env.PUBLIC_URL + "/preview.png"} alt="" className="previewCardImg" />
          </div>
          <div class="previewTextWrapper">
            <h3>Contact Info</h3>
            <p>Call, message , email, whatsapp, telegram, maps , add contact</p>
            <br />
            <h3>Keep in Touch</h3>
            <p>Social media quick buttons Botones de mas uso</p>
            <br />
            <h3>Social Media</h3>
            <p>Instagram, Linkedin, Tictok, Facebook, Youtube, Twitter, Twitch</p>
            <br />
            <h3>Music</h3>
            <p>Spotify, Sound Cloud, music file</p>
            <br />
            <h3>Business</h3>
            <p>Website, Calendly, Yelp, Etsy, Custom Links</p>
            <br />
            <h3>Payments</h3>
            <p>Cash App, Paypal, Venmo, Bitcoin Wallet Address</p>
            <br />
            <h3>Customized</h3>
            <p>Links</p>
          </div>
        </div>

      <footer className="homeSection pb-155 footerContainer">
        <div className="footerVision">
          <img
            className="smallIcon"
            src={`${process.env.PUBLIC_URL + "/prestigiouslogo.png"}`}
            alt="logo"
          />
          <span>
            Presence, prestige, and perfection are what you'll find with our
            most exclusive product - premium, innovative, and elegant. There's
            only one prestigious.
          </span>
        </div>

        <div className="footerMenu">
          <h2 className="textWhite">Site Map</h2>
          <div className="footerMenuLinks">
            <div className="footerMenuLinksColumn">
              <div className="footerLink">
                <a href="/">Home</a>
              </div>
              <div className="footerLink">
                <a href="/about">What's Prestigious?</a>
              </div>
            </div>
            <div className="footerMenuLinksColumn">
              <div className="footerLink">
                <a href="/gallery">Coming Soon</a>
              </div>
              <div className="footerLink">
                <a href="/contact">Contact</a>
              </div>
            </div>
          </div>
        </div>

        <div className="footerContactContainer">
          <h2 className="textWhite">Contact Us</h2>
          <div className="footerContactCard">
            <div className="featuredCardIcon">
              <PhoneIcon size="big" color="#ad8457" />
            </div>
            <div className="footerInfoWrapper">
              <h3 className=" textWhite middleTextSize text-center">
                Phone Number
              </h3>
              <br />
              <span>+714 410 9141</span>
            </div>
          </div>
          <div className="footerContactCard">
            <div className="featuredCardIcon">
              <MailIcon size="big" color="#ad8457" />
            </div>
            <div className="footerInfoWrapper">
              <h3 className=" textWhite middleTextSize text-center">
                Email Address
              </h3>
              <br />
              <span>info@prestigeous.com</span>
            </div>
          </div>
          <div className="footerContactCard">
            <div className="featuredCardIcon">
              <LocationIcon size="big" color="#ad8457" />
            </div>
            <div className="footerInfoWrapper">
              <h3 className=" textWhite middleTextSize text-center">
                Office Address
              </h3>
              <br />
              <span>1671 w katella ave ste 255, Anaheim CA 92802</span>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default ComingSoon;
