import axios from "axios";
import { useEffect, useState } from "react";
import { BASE_URL } from "../../Constants/Constants";
import { useTitle } from "react-use";
import moment from "moment";
import { usePrestigiousContext } from "../../Context/appContext";
import "./Admin.css";

function Admin({ match, history }) {
  const [orders, setOrders] = useState(null);
  const [users, setUsers] = useState(null);
  const [messages, setMessages] = useState(null);
  const [errors, setErrors] = useState(null);
  const keyword = match.params.keyword;
  const pageNumber = match.params.pageNumber || 1;

  useTitle("Prestigious | Admin");

  const {logout, currentUser } = usePrestigiousContext();

  useEffect(() => {
    getOrders();
    getUsers();
    getMessages();
  }, [keyword, pageNumber]);

  const getOrders = async (keyword = "", pageNumber = "") => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${currentUser.token}`,
        },
      };
      const { data } = await axios.get(
        `${BASE_URL}/api/order?keyword=${keyword}&pageNumber=${pageNumber}`,
        config
      );
      //console.log(data)
      setOrders(data);
    } catch (error) {
      console.log(error);
      setErrors(error);
    }
  };

  const getUsers = async (keyword = "", pageNumber = "") => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${currentUser.token}`,
        },
      };
      const { data } = await axios.get(
        `${BASE_URL}/api/users?keyword=${keyword}&pageNumber=${pageNumber}`,
        config
      );
      console.log(data);
      //setInputs(data.users.)
      setUsers(data);
    } catch (error) {
      console.log(error);
      setErrors(error);
    }
  };

  const getMessages = async (keyword = "", pageNumber = "") => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${currentUser.token}`,
        },
      };
      const { data } = await axios.get(
        `${BASE_URL}/api/contact?keyword=${keyword}&pageNumber=${pageNumber}`,
        config
      );
      console.log(data);
      //setInputs(data.users.)
      setMessages(data);
    } catch (error) {
      console.log(error);
      setErrors(error);
    }
  };

  if (errors) return <div>Error {errors}</div>;

  if (!orders && !users && !messages) return <div>Cargando...</div>;

  return (
    <div
      className="adminContainer"
      style={{
        backgroundImage: `url(${process.env.PUBLIC_URL + "/background.png"})`,
      }}
    >
      <div className="adminNavbar">
        <div>
          <h2 className="homeTitle">Prestigious Admin</h2>
        </div>
        <div className="adminLogoWrapper">
        <img alt="logo" className="adminLogo" src={`${process.env.PUBLIC_URL + "/prestigiouslogo.png"}`} />
        </div>
        <div>
        <button onClick={logout} className="homeButton">
                Log out
              </button>
        </div>
      </div>
      <div className="adminheaderContainer">
        <h2 className="pageTitle">Messages</h2>
        <div className="adminSearchWrapper">
          <button
            className="adminBtn"
            onClick={() => history.push("/admin/messages")}
          >
            See all
          </button>
        </div>
      </div>
      <div className="adminTableContainer">
        <table className="adminTable">
          <thead className="adminTableHead">
            <tr className="adminTableRow">
              <th className="adminTableCellHead adminTableCell ">Name</th>
              <th className="adminTableCellHead adminTableCell ">Email</th>
              <th className="adminTableCellHead adminTableCell">Phone</th>
              <th className="adminTableCellHead adminTableCell">Subject</th>
              <th className="adminTableCellHead adminTableCell">Message</th>
            </tr>
          </thead>
          <tbody className="adminTableBody">
            {messages?.contacts.map((message) => {
              return (
                <tr className="adminTableRow" key={message._id}>
                  <td className="adminTableCell adminTableCellBody">
                  {message.name}
                  </td>
                  <td className="adminTableCell adminTableCellBody">
                    {message.email}
                  </td>
                  <td className="adminTableCell adminTableCellBody">
                    {message.phone}
                  </td>
                  <td className="adminTableCell adminTableCellBody">
                    {message.subject}
                  </td>
                  <td className="adminTableCell adminTableCellBody">
                    {message.message}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <br />
      <br />
      <br />
      <div className="adminheaderContainer">
        <h2 className="pageTitle">Orders</h2>
        <div className="adminSearchWrapper">
          <button
            className="adminBtn"
            onClick={() => history.push("/admin/orders")}
          >
            See all
          </button>
        </div>
      </div>
      <div className="adminTableContainer">
        <table className="adminTable">
          <thead className="adminTableHead">
            <tr className="adminTableRow">
              <th className="adminTableCellHead adminTableCell ">Date</th>
              <th className="adminTableCellHead adminTableCell ">Name</th>
              <th className="adminTableCellHead adminTableCell">Email</th>
              <th className="adminTableCellHead adminTableCell">Phone</th>
              <th className="adminTableCellHead adminTableCell ">Address</th>
              <th className="adminTableCellHead adminTableCell">City</th>
              <th className="adminTableCellHead adminTableCell ">State</th>
              <th className="adminTableCellHead adminTableCell">Zip Code</th>
              <th className="adminTableCellHead adminTableCell ">Print Name</th>
              <th className="adminTableCellHead adminTableCell">Status</th>
            </tr>
          </thead>
          <tbody className="adminTableBody">
            {orders?.orders.map((order) => {
              return (
                <tr className="adminTableRow" key={order._id}>
                  <td className="adminTableCell adminTableCellBody">
                    {moment(order.createdAt).format("DD/MM/YY hh:mm a")}
                  </td>
                  <td className="adminTableCell adminTableCellBody">
                    {order.user_name}
                  </td>
                  <td className="adminTableCell adminTableCellBody">
                    {order.user_email}
                  </td>
                  <td className="adminTableCell adminTableCellBody">
                    {order.user_phone}
                  </td>
                  <td className="adminTableCell adminTableCellBody">
                    {order.delivery_address}
                  </td>
                  <td className="adminTableCell adminTableCellBody">
                    {order.delivery_city}
                  </td>
                  <td className="adminTableCell adminTableCellBody">
                    {order.delivery_state}
                  </td>
                  <td className="adminTableCell adminTableCellBody">
                    {order.delivery_zipcode}
                  </td>
                  <td className="adminTableCell adminTableCellBody">
                    {order.print_text}
                  </td>
                  <td className="adminTableCell adminTableCellBody">
                    {order.status}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <br />
      <br />
      <br />
      <div className="adminheaderContainer">
        <h2 className="pageTitle">Users</h2>
        <div className="adminSearchWrapper">
          <button
            className="adminBtn"
            onClick={() => history.push("/admin/users")}
          >
            See all
          </button>
        </div>
      </div>
      <div className="adminTableContainer">
        <table className="adminTable">
          <thead className="adminTableHead">
            <tr className="adminTableRow">
              <th className="adminTableCellHead adminTableCell ">Date</th>
              <th className="adminTableCellHead adminTableCell ">Name</th>
              <th className="adminTableCellHead adminTableCell">Email</th>
              <th className="adminTableCellHead adminTableCell">Active</th>
              <th className="adminTableCellHead adminTableCell">Card Id</th>
              <th className="adminTableCellHead adminTableCell">Profile</th>
            </tr>
          </thead>
          <tbody className="adminTableBody">
            {users?.users.map((user, index) => {
              return (
                <tr className="adminTableRow" key={user._id}>
                  <td className="adminTableCell adminTableCellBody">
                    {moment(user.createdAt).format("DD/MM/YY hh:mm a")}
                  </td>
                  <td className="adminTableCell adminTableCellBody">
                    {user.name}
                  </td>
                  <td className="adminTableCell adminTableCellBody">
                    {user.email}
                  </td>
                  <td className="adminTableCell adminTableCellBody">
                    {user.active ? "Yes" : "No"}
                  </td>
                  <td className="adminTableCell adminTableCellBody">
                    {user.cardId}
                  </td>
                  <td className="adminTableCell adminTableCellBody">
                    <a
                      className="adminBtn"
                      href={`/card/${user.cardId}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Open
                    </a>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default Admin;
