import { useState } from "react";
import { usePrestigiousContext } from "../../Context/appContext";

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { adminLogin, errors } = usePrestigiousContext()

  const handleClick = (e) => {
    e.preventDefault();
    adminLogin(email, password)
   
  };
    return (
      <div
      style={{
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        backgroundImage: `url(${
          process.env.PUBLIC_URL + "/background.png"
        })`,
      }} 
    >
      <img alt="logo" className="logo" src={`${process.env.PUBLIC_URL + "/prestigiouslogo.png"}`} />
      <h1 className="titleText">Prestigious</h1>
      <div className="inputControlWrapper">
        <input
          className="inputField"
          type="text"
          placeholder="Email"
          onChange={(e) => setEmail(e.target.value)}
        />
      </div>
      <div className="inputControlWrapper">
        <input
          className="inputField"
          type="password"
          placeholder="password"
          onChange={(e) => setPassword(e.target.value)}
        />
      </div>
      {errors && <h3 style={{color: "red", padding: "10px"}}>{errors}</h3>}
      <button onClick={handleClick} className="input-submit-button">
        Login
      </button>
    </div>
    );
  }
  
  export default Login;