import { useEffect, useState } from "react";
import axios from "axios";
import { usePrestigiousContext } from "../../../../Context/appContext";
import { BASE_URL } from "../../../../Constants/Constants";

function MusicLinks({ match, history, musicLink, allMusicLinks, setMusicLinks, close }) {
  const [contact, setContact] = useState(musicLink.contact);
  const [type, setType] = useState(musicLink.type);

  const { currentUser } = usePrestigiousContext();

  useEffect(() => {}, []);

  const update = async () => {
    if (contact && type) {
      let musicLinks = allMusicLinks;
      if (musicLink.isEdit) {
        const index = allMusicLinks.findIndex(
          (o) => o._id === musicLink._id
        );
        musicLinks[index].contact = contact;
        musicLinks[index].type = type;
      } else {
        musicLinks = [
          ...allMusicLinks,
          {
            contact,
            type,
          },
        ];
      }

      console.log(musicLinks);
      try {
        const config = {
          headers: {
            Authorization: `Bearer ${currentUser.token}`,
          },
        };

        const { data } = await axios.put(
          `${BASE_URL}/api/users/data/me`,
          {
            musicLinks,
          },
          config
        );
        setMusicLinks(data.musicLinks);
        close();
      } catch (error) {
        console.log("Error", error);
        //handleErrors(error);
      }
    } else {
      alert("Fill all fields");
    }
  };

  const deleteContact = async () => {
    const musicLinks = allMusicLinks.filter(
      (o) => o._id !== musicLink._id
    );
    console.log(musicLinks);
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${currentUser.token}`,
        },
      };

      const { data } = await axios.put(
        `${BASE_URL}/api/users/data/me`,
        {
          musicLinks,
        },
        config
      );
      setMusicLinks(data.musicLinks);
      close();
    } catch (error) {
      console.log("Error", error);
      //handleErrors(error);
    }
  };

  return (
    <div
      style={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <select
        className="inputControl"
        value={type}
        onChange={(e) => setType(e.target.value)}
      >
        <option value="">Pick</option>
        <option value="SPOTIFY">Spotify</option>
        <option value="SOUNDCLOUD">Soundcloud</option>
        <option value="APPLMUSIC">Apple Music</option>
        <option value="YTMUSIC">Youtube Music</option>
        <option value="TIDAL">Tidal</option>
        <option value="PODCAST">Podcast</option>
      </select>
      <input
        className="inputControl"
        value={contact}
        placeholder="Url"
        onChange={(e) => setContact(e.target.value)}
      />
      <button className="saveButton" onClick={update}>
        {musicLink.isEdit ? "Edit" : "Add"}
      </button>
      {musicLink.isEdit && (
        <button className="saveButton" onClick={deleteContact}>
          Delete
        </button>
      )}
    </div>
  );
}

export default MusicLinks;
