import { useEffect, useState } from "react";
import axios from "axios";
import { usePrestigiousContext } from "../../../../Context/appContext";
import { BASE_URL } from "../../../../Constants/Constants";

function CustomLinks({
  match,
  history,
  customLink,
  allCustomLinks,
  setCustomLinks,
  close,
}) {
  const [text, setText] = useState(customLink.text);
  const [link, setLink] = useState(customLink.link);

  const { currentUser } = usePrestigiousContext();

  useEffect(() => {}, []);

  const update = async () => {
    if (text && link) {
      let customLinks = allCustomLinks;
      if (customLink.isEdit) {
        const index = allCustomLinks.findIndex((o) => o._id === customLink._id);
        customLinks[index].text = text;
        customLinks[index].link = link;
      } else {
        customLinks = [
          ...allCustomLinks,
          {
            text,
            link,
          },
        ];
      }

      console.log(customLinks);
      try {
        const config = {
          headers: {
            Authorization: `Bearer ${currentUser.token}`,
          },
        };

        const { data } = await axios.put(
          `${BASE_URL}/api/users/data/me`,
          {
            customLinks,
          },
          config
        );
        setCustomLinks(data.customLinks);
        close();
      } catch (error) {
        console.log("Error", error);
        //handleErrors(error);
      }
    } else {
      alert("Fill all fields");
    }
  };

  const deleteCustom = async () => {
    const customLinks = allCustomLinks.filter((o) => o._id !== customLink._id);
    console.log(customLinks);
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${currentUser.token}`,
        },
      };

      const { data } = await axios.put(
        `${BASE_URL}/api/users/data/me`,
        {
          customLinks,
        },
        config
      );
      setCustomLinks(data.customLinks);
      close();
    } catch (error) {
      console.log("Error", error);
      //handleErrors(error);
    }
  };

  return (
    <div style={{
      height: "100%",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
    }}>
      <input
        className="inputControl"
        placeholder="Text"
        value={text}
        onChange={(e) => setText(e.target.value)}
      />
      <input
        className="inputControl"
        placeholder="Url"
        value={link}
        onChange={(e) => setLink(e.target.value)}
      />
      <button className="saveButton" onClick={update}>
      {customLink.isEdit ? "Edit" : "Add"}
      </button>
      {customLink.isEdit && (
        <button className="saveButton" onClick={deleteCustom}>
          Delete
        </button>
      )}
    </div>
  );
}

export default CustomLinks;
