import { useEffect, useState } from "react";
import axios from "axios";
import { BASE_URL } from "../../Constants/Constants";
import { usePrestigiousContext } from "../../Context/appContext";
import "./Admin.css";

function User({ match, history }) {
  const [user, setUser] = useState(null);
  const [error, setError] = useState(false);
  const id = match.params.id;

  const { currentUser } = usePrestigiousContext();

  useEffect(() => {
    getUser();
  }, [id]);

  const getUser = async () => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${currentUser.token}`,
        },
      };

      const { data } = await axios.get(`${BASE_URL}/api/users/${id}`, config);
      setUser(data);
      //console.log("Profile", id, data);
      //setLoading(false);
    } catch (error) {
      console.log("Error", error);
      setError(error);
    }
  };

  if (error) return <div>Error {error}</div>;

  if (!user) return <div>Loading...</div>;

  return (
    <div
      className="adminContainer"
      style={{
        backgroundImage: `url(${process.env.PUBLIC_URL + "/background.png"})`,
      }}
    >
      <div className="adminheaderContainer">
        <h2 className="pageTitle">{user.name}</h2>
        <div className="adminSearchWrapper">
        <h2 className="pageTitle">Profile views: {user.profileViews}</h2>
        </div>
      </div>
      <div className="adminTableContainer">
        <table className="adminTable">
          <thead className="adminTableHead">
            <tr className="adminTableRow">
              <th className="adminTableCellHead adminTableCell ">Type</th>
              <th className="adminTableCellHead adminTableCell ">Section</th>
              <th className="adminTableCellHead adminTableCell ">Count</th>
            </tr>
          </thead>
          <tbody className="adminTableBody">
            {user.clickedCount?.sort((a, b) => a.count > b.count ? -1 : 1).map((item) => {
              return (
                <tr className="adminTableRow" key={user._id}>
                  <td className="adminTableCell adminTableCellBody">
                    {item.type}
                  </td>
                  <td className="adminTableCell adminTableCellBody">
                    {item.category}
                  </td>
                  <td className="adminTableCell adminTableCellBody">
                    {item.count}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}
/*<h2 className="pageTitle">Id: {user._id}</h2>
<h2 className="pageTitle">Name: {user.name}</h2>
<h2 className="pageTitle">Email: {user.email}</h2>
<h2 className="pageTitle">Phone: {user.phone}</h2>
<h2 className="pageTitle">Position: {user.position}</h2>
<h2 className="pageTitle">Location: {user.location}</h2>
<h2 className="pageTitle">Card Id: {user.cardId}</h2>
<h2 className="pageTitle">Created: {moment(user.createdAt).format("DD/MM/YY hh:mm a")}</h2>
<h2 className="pageTitle">Profile views: {user.profileViews}</h2>*/
export default User;
