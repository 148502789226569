import React from "react";

export default function MessageIcon({ size, color = "#4691f6" }) {
  return (
    <div>
      <span className="icon-container">
        <svg 
        width={size === "big" ? "1.5em" : "1.2em"}
        height={size === "big" ? "1.5em" : "1.2em"}
        fill={color}
        viewBox="0 0 20 20">
          <path
            fill={color}
            d="M17.211,3.39H2.788c-0.22,0-0.4,0.18-0.4,0.4v9.614c0,0.221,0.181,0.402,0.4,0.402h3.206v2.402c0,0.363,0.429,0.533,0.683,0.285l2.72-2.688h7.814c0.221,0,0.401-0.182,0.401-0.402V3.79C17.612,3.569,17.432,3.39,17.211,3.39M16.811,13.004H9.232c-0.106,0-0.206,0.043-0.282,0.117L6.795,15.25v-1.846c0-0.219-0.18-0.4-0.401-0.4H3.189V4.19h13.622V13.004z"
          ></path>
        </svg>
      </span>
    </div>
  );
}
